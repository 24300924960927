import { Component, OnInit, Input, Injectable, ViewChild, ElementRef, Output, EventEmitter, TemplateRef } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpEventType } from '@angular/common/http';


import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ModalService } from '../../modal/modal.service';
import { Util } from 'src/app/core';
import { Comment } from 'src/app/core/models/comment';

@Component({
  selector: 'lib-comments',
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"]
})

export class CommentsComponent {
  @Input() apiURL: string;
  @Input() modifiable: boolean;
  @Input() showHistory: boolean;
  @Input() public set items(items:Comment[]) {
    this.useAPItoGetItems = false;
    // $$Implements SW.FE.CMNT.LIST.DELETED
    this.comments = items.filter(c => !c.deleted);
    this.deleted = items.filter(c => c.deleted);
  }
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onMenu: EventEmitter<any> = new EventEmitter();

  dateTimeString = Util.dateString;

  useAPItoGetItems = true;
  comments: Comment[];
  deleted: Comment[];
  constructor(
    private http: HttpClient,
    private modalService:ModalService
  ) { }

  commentRows(c:Comment) {
    let cmnt = c.text||"";
    return 1+(cmnt.match(/\n/gs)?.length??0);
  }

  onKeyPress(event:KeyboardEvent) {
    if (event.key == "Enter" && !event.shiftKey)
      (<HTMLElement>event.target).blur();
  }
  originalComment:string ="";

  startEditComment(comment: string) {
    this.originalComment = comment;
  }

  // $$Implements SW.FE.CMNT.UPDATE
  updateComment(id:number, event:Event) {
    let target:{value:string} = <any>event.target;
    let originalComment = this.originalComment;
    let comment = target.value;
    if (originalComment == comment)
      return;
    this.http.put<Comment>(`${this.apiURL}/cmnt/${id}`,{comment})
    .subscribe(res => {
      let idx = this.comments.findIndex(c => c.id == id);
      if (idx >= 0)
        this.comments[idx] = Comment.fixDates(res);
      this.onUpdate.emit();
    },
    error => {
      target.value = originalComment;
      this.error("Failed to updated description",error)
    }
    );
  }

  // $$Implements SW.FE.CMNT.DEL
  onDeleteBtn(id: number) {
    let msg = "Would you like to delete this comment?";
    this.modalService.ask("Delete comment", msg)
    .pipe(
      switchMap(res => {
        return res ?
          this.http.delete(`${this.apiURL}/cmnt/${id}`)
          : of(0)
      })
    )
    .subscribe(
      res => {
        if (res!==0){
          let idx = this.comments.findIndex(c => c.id == id);
          if (idx >= 0) {
            this.comments[idx].deleted = true;
            this.deleted = [this.comments[idx], ...this.deleted];
            this.comments = this.comments.filter(c => c.id != id);
          }
          this.onUpdate.emit();
        }
      },
      error => {
        this.error("Failed to delete comment", error)
      }
    );
  }

  cmntHist:Comment[] = [];
  @ViewChild("cmntHistTmpl") cmntHistTmpl: TemplateRef<any>;

  // $$Implements SW.FE.CMNT.HIST
  onHistoryBtn(id: number) {
    this.http.get<Comment[]>(`${this.apiURL}/cmnt/${id}`).pipe(
      map(r => {
        return r.map(c => Comment.fixDates(c));
      })
    )
    .subscribe(
      res => {
        if (!res.length) {
          this.modalService.notify(
            "Comments history",
            "This comment does not have any changes yet"
            ).subscribe();
          return;
        }
        this.cmntHist = res;
        this.modalService.show({
          title: "Comments history",
          template: this.cmntHistTmpl
        }).subscribe();
      },
      error => {
        this.error("Failed to fetch history", error)
      }
    );
  }

  error(msg,err) {
    err = err||{};
    msg += ":<br>" + (Util.errorMessage(err) || "");
    this.modalService.notify("Error",msg).subscribe();
  }
}
