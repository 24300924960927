import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


@Injectable({providedIn: 'root'})
export class TestingService {
  constructor(
    private cookieService: CookieService
  ) { 
  }

  get testing() {
    return !!this.cookieService.get('test')
  }

}
