import { Util } from "../util/util";

// $$Implements SW.BE.XI.TRVL_AVDSRY.DTO
export class Advisory {
  level: number;
  title: string;
  publishedDate: Date = new Date();
  link: string;
  countryName: string;
  countryCode: string;
  description: string;

  static readonly Dates: string[] = Util.DatePropertiesKeys(new Advisory());
  static fixDates(a:Advisory) {
    return Util.FixDates(a, Advisory.Dates);
  }
}