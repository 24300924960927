import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";

import { DatatableComponent, SelectionType, SortDirection, SortPropDir, TableColumn } from "@siemens/ngx-datatable";
import { FavAccount } from "src/app/core/models/fav-account";
import { nameof } from "ts-simple-nameof";
import { FavoritesService, Util } from "src/app/core";
import { map, switchMap } from "rxjs/operators";
import { ModalService } from "../../modal/modal.service";
import { MfkString, emptyMfk, stringify } from "@uiowa/uiowa-mfk";
import { of } from "rxjs";

export const MfkFields = [
  "Fund",
  "Org",
  "Dept",
  "Subdept",
  "Grant/Pgm",
  "Iact",
  "Oact",
  "Dact",
  "Fn",
  "Cctr",
];

class FavAcctRow extends FavAccount {
  constructor(a:FavAccount) {
    super();
    Util.copy(a, this);
    a.account.split("-").forEach((s,i)=>(<any>this)[i]=s);
  }
}

@Component({
  selector: 'lib-fav-accnts',
  templateUrl: "./fav-accnts.component.html",
  styleUrls: ["./fav-accnts.component.scss"]
})
export class FavAccnts implements OnInit {
  @Input() fields: string[];
  @Input() newFavorite: string = undefined;
  @Output() OnSelect: EventEmitter<any> = new EventEmitter();

  rows: FavAcctRow[];
  cols: TableColumn[];
  sorts: SortPropDir[] = [{ dir: SortDirection.asc, prop: nameof<FavAcctRow>(x => x.description) }];
  readonly SelectionType = SelectionType;
  constructor(
    private favSvc: FavoritesService,
    private modal: ModalService
  ) { }

  @ViewChild('datatable') datatable: DatatableComponent;
  @ViewChild('editDelTmpl', { static: true }) editDelTmpl: TemplateRef<any>;

   show=false;
  ngOnInit(): void {
    const em = Util.getEM();
    if (!this.fields)
      this.fields = MfkFields;
    this.cols = [
      {
        width: 3 * em,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        cellTemplate:this.editDelTmpl,
      },
      {
        name: "Description", width:300,  prop: nameof<FavAcctRow>(x => x.description) }
    ];
    this.cols= this.cols.concat(this.fields.map((f, i) => { return { 
      name: f, 
      prop: i,
      resizeable:false,
      canAutoResize:true
//      $$valueGetter: ((r:FavAcctRow) =>r.elements[i])
      };
    }));
    this.favSvc.accounts$.subscribe(accounts => setTimeout(()=>this.updateAccounts(accounts)));

    if (this.newFavorite)
      setTimeout(()=>this.Edit(null));
  }

  updateAccounts(accnts:FavAcctRow[]) {
    const em = Util.getEM();
    this.rows = [...accnts.map(a => new FavAcctRow(a))];
    this.cols.forEach(c => {
      if (!c.name)
        return;
      let l = c.name.length;
      this.rows.forEach(r => {
        l = Math.max(l, r[c.prop].length)
      })
      c.maxWidth = l * .9 * em;
    });
  }

  rowClass = (row:FavAcctRow)=> {
    let c =  (this.OnSelect.observers.length) ?"cursor-pointer":"";
    if (!row.valid)
    c += " text-danger"
    return c;
  }

  onRowSelect(r: {selected:FavAcctRow[]}) {
    // if (!this.OnSelect.observers.length)
    //   this.Edit(r.selected[0]);
    this.OnSelect.emit(r.selected[0].account);
  }

  mfk = emptyMfk();
  description="";
  @ViewChild("editAccntDlg") editAccntDlg: TemplateRef<any>;
  Edit(row:FavAcctRow) {
    row = row || new FavAcctRow({
      id: 0,
      account: this.newFavorite,
      description: ""
    });
    this.mfk = Object.assign(emptyMfk(), new MfkString(row.account).mfk);
    this.description = row?.description||"";
    this.modal.show({
      title: row?"Edit favorite account":"Add favorite account",
      cancelBtn: "Cancel",
      template: this.editAccntDlg,
      elementClass: "wide",
      okOnEnter: true,
      okBtnDisabled: ()=>!this.description,
      okBtnHelp: () => (!this.description)?"Description is required":""
    })
      .pipe(
        switchMap(res => {
          if (!res)
            return of(0);
          row.account = stringify(this.mfk);
          row.description = this.description;
          if (row.id) {
            return this.favSvc.updateAccnt(row);
          } else {
            return this.favSvc.addAccnt(row);
          }
        })
      )
      .subscribe(
        res => {
          if (res !== 0)
            this.favSvc.updateAccounts();
        },
        error => {
          this.modal.error(error).subscribe(()=>setTimeout(()=>this.Edit(row)))
        }
      );
  }

  Del(row:FavAcctRow) {
    this.modal.ask(
      "Remove from favorites",
      `Remove "${row.description}" from favorites?`
    )
      .pipe(
        switchMap(res => {
          return res ?
            this.favSvc.delAccnt(row)
            : of(0)
        })
      )
      .subscribe(
        res => {
          if (res !== 0)
            this.favSvc.updateAccounts();
        },
        error => {
          this.modal.error(error).subscribe()
        }
      );
  }
}