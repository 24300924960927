import { Util } from "../util/util";
import { PCard } from "./pcard";
import { Person } from "./person";

export class Role {
  id: number;
  roleId: number;
  person?: Person;
  accScope?: string;
  pcard?: PCard;
  scope: string;
  creator: Person;
  createdOn: Date = new Date();

  static readonly Dates: string[] = Util.DatePropertiesKeys(new Role());
  static fixDates(r: Role) {
    return Util.FixDates(r, Role.Dates);
  }
}