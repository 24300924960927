import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompleterItem } from '@vldmr-bus/ng-completer';
import { PCard } from 'src/app/core/models/pcard';
import { PCardData } from './pcard-data';
import { Observable } from 'rxjs';
import { PCardService } from 'src/app/core';

@Component({
  selector: 'lib-pcard-search',
  templateUrl: './pcard-search.component.html',
  styleUrls: ['./pcard-search.component.scss']
})
export class PCardSearchComponent implements OnInit {
  @Input() placeholder: string = 'Card holder name or last 4 digits of card number';
  @Output() onSelected: EventEmitter<PCard> = new EventEmitter();

  selectedCard: PCard;
  pcardData: PCardData;

  constructor(
    private elRef: ElementRef,
    private pcardSvc: PCardService
  ) { }

  ngOnInit(): void {
    this.pcardData = new PCardData(this.search);
  }

  search: (qry: string) => Observable<PCard[]> = qry => {
    return this.pcardSvc.search(qry);
  }

  onComplete(selected: CompleterItem) {
    let p = selected.originalObject as PCard;
    this.onSelected.emit(p);
    (<any>document.activeElement).blur();
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.key == "Escape") {
      (<any>document.activeElement).blur();
      setTimeout(() => this.elRef.nativeElement.parentElement.dispatchEvent(e));
    }
  }
}