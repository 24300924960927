import { Util } from "../util/util";
import { NotificationSubj } from "./notificationSubj";

// $$Implements SW.BE.DTO.N10N
export class Notification extends NotificationSubj {
  body = "";

  static readonly Dates: string[] = Util.DatePropertiesKeys(new Notification());
  static fixDates(e: Notification) {
    return Util.FixDates(e, Notification.Dates);
  }

}