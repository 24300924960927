<div *ngIf="comments&&comments.length">
  <ul class="container w-100" style="font-size: 13px;">
    <li class="row justify-content-center" *ngFor="let c of comments">
      <ng-container [ngTemplateOutlet]="commentLine" [ngTemplateOutletContext]="{c:c}"></ng-container>
    </li>
  </ul>
</div>

<!-- $$Implements SW.FE.CMNT.LIST.DELETED -->
<div *ngIf="deleted&&deleted.length">
  <b>Deleted comments:</b>
  <ul class="container w-100" style="font-size: 13px;">
    <li class="row" *ngFor="let c of deleted">
      <ng-container [ngTemplateOutlet]="commentLine" [ngTemplateOutletContext]="{c:c}"></ng-container>
    </li>
  </ul>
</div>

<!-- $$Implements SW.FE.CMNT.LIST -->
<ng-template #commentLine let-c="c">
  <div class="col-12">
    <div *ngIf="modifiable && c.editable && !c.deleted" class="row align-items-start">
      <textarea class="col-10" [rows]="commentRows(c)"
        (focus)="startEditComment($any($event.target).value)" 
        (blur)="updateComment(c.id,$event)"
        (keypress)="onKeyPress($event)"
        [style]="c.viewTypeCd=='I'?'color:red;':''"
      >{{c.text}}</textarea>
      <div class="col-1 d-flex">
        <a *ngIf="showHistory" (click)="onHistoryBtn(c.id)" class="btn-xs btn-success mx-2">
          <i class="fa-solid fa-clock-rotate-left"></i>
        </a>
        <a (click)="onDeleteBtn(c.id)" class="btn btn-sm btn-danger  rounded-pill" data-loading-text="Deleting...">
          <i class="fa-solid fa-trash"></i>
        </a>
      </div>
    </div>
    <div class="row" *ngIf="!modifiable || !c.editable || c.deleted">
      <div  class="col-10 text-pre" [style]="c.viewTypeCd=='I'?'color:red;':''">{{c.text}}</div>
      <div class="col-1">
        <a *ngIf="showHistory" (click)="onHistoryBtn(c.id)" class="btn-xs btn-success mx-2">
          <i class="fa-solid fa-clock-rotate-left"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="col-12" style="font-size: smaller"><i>Created by {{c.createdBy}} &#64;
      {{dateTimeString(c.createdOn)}}&nbsp;{{c.deleted?"Deleted":"Last updated"}} by {{c.modifiedBy}} &#64; {{dateTimeString(c.modifiedOn)}}</i>
    <hr class="mt-1 mb-2"/>
  </div>
</ng-template>

<ng-template #cmntHistTmpl>
  <ul style="max-height: 75vh; overflow: auto;">
    <li *ngFor="let c of cmntHist">
      {{c.text}}<br>
      <span style="font-size: smaller"><i>By {{c.modifiedBy}}
          &#64; {{dateTimeString(c.modifiedOn)}}</i></span>
    </li>
  </ul>
</ng-template>