import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OrgUnit } from "src/app/core/models/org-unit";
import { Person } from "src/app/core/models/person";

@Component({
  selector: "lib-org-tree",
  templateUrl: "./org-tree.component.html",
  styleUrls: ["./org-tree.component.css"],
})
export class OrgTreeComponent {
  @Input() orgTree: OrgUnit;
  @Input() select: boolean = false;
  @Input() showFavUnits: boolean = false;
  @Output() onUnitExpand: EventEmitter<OrgUnit> = new EventEmitter();
  @Output() onUnitSelectChange: EventEmitter<OrgUnit> = new EventEmitter();
  
  @Input() showPeople: boolean = false;
  @Input() manageFavPeople: boolean = false;
  @Output() onRemovePerson = new EventEmitter<Person>();
  @Input() removePersonTitle: string = "Remove";
  @Output() onAddPerson = new EventEmitter<Person>();
  @Input() addPersonTitle: string = "Add";


  constructor() {}

  onExpand(unit: OrgUnit) {
    unit.expanded = !unit.expanded;
    this.onUnitExpand.emit(unit);
  }

  onSelectChange(unit: OrgUnit) {
    this.onUnitSelectChange.emit(unit);
  }
}