<!-- $$Implements SW.FE.HUB.DLG, SW.FE.HUB.DLG.SELECT -->
<ng-template #hubDlg>
  <div class="container mb-1">
    <div class="d-flex w-100 ">
      <div class="w-25 input-group position-relative d-inline-flex align-items-center">
        <input type="text" [formControl]="svc.filterFC" class="flex-fill" placeholder="Filter files"/>
        <i class="fa fa-times position-absolute" 
          style="right: 10px;cursor: pointer;z-index: 100;"
          (click)="svc.filterFC.reset()"
        >
        </i>
      </div>

      <div class="dropdown">
        <button class="btn btn-sm btn-success dropdown-toggle m-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{this.svc.orderBy}}
        </button>
        <ul class="dropdown-menu">
          <li *ngFor="let k of svc.orderingNames" (click)="svc.setOrderBy(k)">
            <button class="dropdown-item" type="button">{{k}}</button>
          </li>
        </ul>
      </div>

      <button class="btn btn-sm btn-success m-1 fa fa-arrow-down-wide-short" [class]="svc.orderBtnClass()" (click)="svc.toggleSort()"></button>

      <div class="form-check flex-fill d-flex align-items-center">
        <label class="form-check-label flex-fill">Remove from user files after attaching
          <input type="checkbox" [(ngModel)]="svc.deleteAfterAttach" class="form-check-input"/>
        </label>
      </div>
    </div>

    <div class="d-flex flex-column flex-fill h-100">
      <div style="min-height: 50vh !important; overflow-y: auto;">
        <div class="h-50">
          <h5>Files of {{this.svc.personName}}</h5>
          <lib-attachments 
            class="vscroll"
            #otherFilesList 
            [apiURL]="'/api/person/att'" 
            [items]="svc.otherFiles"
            [modifiable]="false" 
            [selectable]="true" 
            [itemName]="'file'" 
            [extrasTemplate]="extras"
            (onSelect)="svc.onSelect($event+svc.myFiles.length)"
          >
          </lib-attachments>
        </div>
        <div [class]="this.svc.onlyMine?'h-100':'h-50'">
          <h5>My files</h5>
          <lib-attachments 
            #myFilesList 
            class="vscroll"
            [apiURL]="'/api/person/att'" 
            [items]="svc.myFiles"
            [modifiable]="false" 
            [selectable]="true" 
            [itemName]="'file'" 
            [extrasTemplate]="extras"
            (onSelect)="svc.onSelect($event)"
            style="overflow-y: auto;"
          >
          </lib-attachments>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #extras let-a>

  <div *ngIf="!!a.mfk" class="row">
    <div class="col-6">
      MFK: {{a.mfk}}
    </div>
    <div class="col-6">
      {{a.bjust}}
    </div>
  </div>
</ng-template>