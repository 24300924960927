import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, interval, merge, of } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { NotificationSubj } from '../models/notificationSubj';
import { Notification } from '../models/notification';
import { Dataset, DatasetCommand } from '../models/dataset';
import { Util } from '../util/util';
import * as signalR from '@microsoft/signalr';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
      private http: HttpClient,
      private readonly userService: UserService,
  ) {
    this._new.closed = true;
    this.userService.user$.subscribe((u) => {
      if (u?.id) {
        this._new.closed = false;
        this.connection = new signalR.HubConnectionBuilder().withUrl("/hub/n10ns").build();
        this.connection.on("new", () => this._new.next(void 0));
        this.connection.start().catch(err => console.error(err.toString()))
      }
    });

  }
  connection: signalR.HubConnection;
  _new = new BehaviorSubject<void>(void 0);
  update$:Observable<void> = this._new.asObservable();
  
  top() {
    return this.http.get<{ unread: number, total: number, notifications: NotificationSubj[] }>("api/n10ns/top").pipe(
      map(res => {
        res.notifications.forEach(d => NotificationSubj.fixDates(d));
        return res;
      })
    );
  }

  get(cmd: DatasetCommand) {
    let params = Util.CommandToHttpParams(cmd);
    return this.http.get<Dataset<NotificationSubj>>("api/n10ns", { params }).pipe(
      map(ds => {
        ds.data.forEach(d => NotificationSubj.fixDates(d));
        return ds;
      })
    );
  }

  getAdmin(cmd: DatasetCommand) {
    let params = Util.CommandToHttpParams(cmd);
    return this.http.get<Dataset<NotificationSubj>>("api/n10ns/adm", { params }).pipe(
      map(ds => {
        ds.data.forEach(d => NotificationSubj.fixDates(d));
        return ds;
      })
    );
  }

  read(id:number) {
    return this.http.get<Notification>(`api/n10ns/${id}`).pipe(
      map(nt => {
        Notification.fixDates(nt);
        return nt;
      })
    );
  }

  viewed(id:number) {
    return this.http.put(`api/n10ns/${id}`,null).pipe(
      tap(() => this._new.next(this._new.getValue()))
    );
  }

  delete(ids:number[]) {
    return this.http.delete(`api/n10ns`,{body:ids}).pipe(
      tap(() => this._new.next(this._new.getValue())),
      switchMap(()=>of(ids.length))
    );
  }

  optOut(cmd:{n10nId?:number,tmplId?:number}) {

    return this.http.post(`api/n10ns/optout`, Util.CommandToFormData(cmd), { responseType: "text" });
  }

  templateName(id:number) {
    return this.http.get(`api/n10ns/name/${id}`, { responseType: "text" });
  }
}
