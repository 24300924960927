import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { AppEvent, AppEventType } from "../models/app-event";

@Injectable({
  providedIn: 'root',
})
export class AppEventsService {

  private eventBrocker = new Subject<AppEvent<any>>();

  on(...eventTypes: AppEventType[]): Observable<AppEvent<any>> {
    return this.eventBrocker.pipe(filter(event => eventTypes.some(t => event.type === t)));
  }

  dispatch<T>(event: AppEvent<T>): void {
    this.eventBrocker.next(event);
  }

}