import { Expense } from "./expense";
import { StatusChange } from "./status-change";
import { Comment } from "./comment";
import { Util } from "../util/util";
import { Attachment } from "./attachment";
import { Change } from "./change";

// $$Implements SW.BE.DTO.XPR
export class XpReport {
    id: number = 0;
    personId: number = 0;
    personName: string = "";
    loginId: string = "";
    creator: string = "";
    createdOn: Date = new Date();
    modifiedBy: string = "";
    modifiedOn: Date = new Date();
    glId: string = "";
    description?: string = "";
    packageId: number = 0;
    status: number=0;
    statusChanges?: StatusChange[];
    numExp: number=0;
    numRecExp: number=0;
    editable?: boolean;
    commentable?: boolean;
    canPassAudit?: boolean;
    reconciler?: boolean;
    comments?: Comment[];
    expenses?: Expense[];
    attachments?: Attachment[];
    changes?: Change[];

    static readonly Dates: string[] = Util.DatePropertiesKeys(new XpReport());
    static fixDates(r:XpReport) {
        if (r.comments)
            r.comments.map(c=>Comment.fixDates(c));
        if (r.statusChanges)
            r.statusChanges.map(c=>StatusChange.fixDates(c));
        if (r.expenses)
            r.expenses.map(x=>Expense.fixDates(x));
        if (r.attachments)
            r.attachments.map(x=>Attachment.fixDates(x));
        if (r.changes)
            r.changes.map(x=>Change.fixDates(x));
        return Util.FixDates(r,XpReport.Dates);
    }
}
