import { nameof } from "ts-simple-nameof";
import { Util } from "../util/util";
import { AddrCity } from "./addr-city";
import { Address } from "./address";
import { Advisory } from "./advisory";

// $$Implements SW.BE.DTO.CITY
export class City {
  name: string;
  code?: number;
}

// $$Implements SW.BE.DTO.STATE
export class State {
  name: string;
  code?: string;
  cities: City[]
}

// $$Implements SW.BE.DTO.CNTRY
export class Country {
  name: string;
  code?: string;
  states: {[key:string]:State}
}

// $$Implements SW.BE.DTO.TRIP_DEST
export class TripDest implements AddrCity {
  id: number = 0;
  begin: Date = new Date();
  end: Date = new Date();
  comment: string = "";
  country: string = "";
  countryCode?: string = "";
  stateCode?: string = "";
  cityCode?: number = 0;
  cityName: string = "";
  cityStateCountry: string = "";
  addresses: Address[] = [];
  org?: Address;
  advisory?: Advisory;

  static readonly Dates: string[] = Util.DatePropertiesKeys(new TripDest());
  static fixDates(r: TripDest) {
    return Util.FixDates(r, TripDest.Dates);
  }
  static compare = (a: TripDest, b: TripDest) => (a.begin > b.begin) ? 1 : (a.begin < b.begin) ? -1 : (a.end > b.end) ? 1 : (a.end < b.end) ? -1 : a.cityStateCountry.localeCompare(b.cityStateCountry)
}