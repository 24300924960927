<section class="d-flex flex-wrap flex-shrink-0 justify-content-center">
  <div class="dashboard-nav-home d-flex justify-content-center flex-wrap w-100">
    <div *ngFor="let t of tiles" class="dashboard-nav-item">
      <a [routerLink]="['/',t.path]" class="home-link" aria-label="t.data.label">
        <div class="icon-container">
          <fa-icon *ngIf="t.data.icon" [icon]="t.data.icon" [fixedWidth]="true" aria-hidden="true"
            class="fa-xl"></fa-icon>
          <title>{{t.data.label}}</title>
        </div>
        {{t.data.label}}
      </a>
    </div>
  </div>
  <div></div>
</section>