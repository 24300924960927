import { Injectable, Injector, TemplateRef } from '@angular/core';
import { NgElement, WithProperties, createCustomElement } from '@angular/elements';

import { ModalComponent} from "./modal.component"
import { Observable, Subscriber } from 'rxjs';
import { faL } from '@fortawesome/free-solid-svg-icons';

@Injectable({ providedIn: 'root' })
export class ModalService {

  constructor(injector: Injector) {
    if(!customElements.get('modal-element')) {
      const ModalElement = createCustomElement(ModalComponent, { injector });
      customElements.define('modal-element', ModalElement);
    }
  }

  errorMessage(err): string {
    if (typeof err == "string")
      return err;
    else if (err.error && typeof err.error == "string")
      return err.error;
    else if (err.error && err.error.Message && typeof err.error.Message == "string")
      return err.error.Message;
    else if (err.error && err.error.message && typeof err.error.message == "string")
      return err.error.message;
    else if (err.message && typeof err.message == "string")
      return err.message;
    else
      return null;
  }


  ask(title, message): Observable<boolean> {
    return this.show({title,message,cancelBtn:"Cancel",okOnEnter:true});
  }

  notify(title, message): Observable<boolean> {
    return this.show({ title, message, okOnEnter:true});
  }

  error(err:any, message?:string, title?:string): Observable<boolean> {
    let msg = this.errorMessage(err)||"No error description";
    return this.notify(title||"Error",`${message||""}${message?": ":""}${msg}`);
  }

  modalEl: NgElement & WithProperties<ModalComponent>;
  okBtnValue:any;
  okOnEnter?: boolean|((e?:KeyboardEvent) => boolean);
  show(args:{
    title:string, 
    message?:string, 
    okBtn?: string,
    cancelBtn?:string,
    template?:TemplateRef<any>,
    okBtnHelp?: (() => string) | string,
    okBtnDisabled?: (() => boolean) | boolean,
    okBtnValue?:any,
    okOnEnter?: boolean | ((e?: KeyboardEvent) => boolean),
    elementClass?: string
  }): Observable<boolean> {
    if (this.modalEl)
      this.close();
    this.okBtnValue = args.okBtnValue??true;
    this.okOnEnter = (args.okOnEnter===undefined)?true:args.okOnEnter;
    return new Observable<any>(sub => {
      const modalEl: NgElement & WithProperties<ModalComponent> = document.createElement('modal-element') as any;
      modalEl.title = args.title;
      modalEl.message = args.message;
      modalEl.okBtn = args.okBtn;
      modalEl.cancelBtn = args.cancelBtn;
      modalEl.template = args.template;
      if (args.elementClass)
        modalEl.className = args.elementClass;
      if (args.okBtnHelp)
        modalEl.okBtnHelp = args.okBtnHelp;
      if (args.okBtnDisabled)
        modalEl.okBtnDisabled = args.okBtnDisabled;
      modalEl.addEventListener('closed', ()=>this.onClose());
      document.addEventListener('keydown', (e)=>this.onKeydown(e));
      modalEl.state="opened";
      modalEl.style.display="contents";
      document.body.appendChild(modalEl);
      this.modalEl = modalEl;
      this.modalEl.subscriber = sub;
      let focus = document.querySelector(".focused-initially");
      if (focus){
      if (focus.tagName != 'INPUT')
        focus = document.querySelector(".focused-initially input")
        if (focus)
          (<any>focus).focus();
      }
    });
  }

  onKeydown(e:KeyboardEvent) {
    switch (e.key){
    case "Escape":
      this.onClose()
      break;
    case "Enter":
      if (this.okOnEnter )
        if (typeof(this.okOnEnter) == 'boolean' || this.okOnEnter(e))
        this.ok();
      break;
    }
  }

  onClose() {
    if (!this.modalEl)
      return;
    let result = this.modalEl?.result;
    this.modalEl.subscriber?.next(result ? this.okBtnValue : false);
    this.close();
  }
  close() {
    document.removeEventListener('close',this.onKeydown)
    if (!this.modalEl)
      return;
    this.modalEl.removeEventListener('close',this.onClose)
    document.body.removeChild(this.modalEl);
    this.modalEl.subscriber?.complete();
    this.modalEl.subscriber?.unsubscribe();
    this.modalEl.subscriber = undefined;
    this.modalEl = undefined;
  }
  ok() {
    (<HTMLElement>document.querySelector("#modalComponentOkButton"))?.click();
  }
}
