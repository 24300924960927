import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MyLinksRoutingModule } from './my-links-routing.module';
import { MyLinksComponent } from './my-links.component';
import { SharedModule } from '../shared';
import { FavoritesService, Util } from '../core';
import { ContextMenuModule } from '@vldmr-bus/ngx-contextmenu';


@NgModule({
  declarations: [
    MyLinksComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MyLinksRoutingModule,
    ContextMenuModule.forRoot({
      autoFocus: true,
      useBootstrap4: true,
    }),
  ],
  providers: [
    FavoritesService,
    Util
  ]
})
export class MyLinksModule { }
