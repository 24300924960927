<div class="pcard-search border p-1">
  <div class="bg-success p-1">
    <ng-completer
      [(ngModel)]="selectedCard"
      (selected)="onComplete($event)"
      [datasource]="pcardData"
      [minSearchLength]="4"
      [inputClass]="'search-input'"
      [placeholder]="placeholder"
      [textSearching]="'Please wait...'"
      style="min-width: 100%;"
      autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
      (keydown) = "onKeyDown($event)"
    >
    </ng-completer>
  </div>
</div>

