export class ApplicationUser {
  /**
   * Application User View Model.
   */
  roles = new Set<string>();
  constructor(
    public id: number = 0,
    public loginId: string = '',
    public originalUser?: string | null,
    roles: Set<string>=null
  ) {
    roles&&roles.forEach(r => {this.roles.add(r)});
  }

  isAuthenticated(): boolean {
    return !!this.loginId;
  }

  isBasicUser(): boolean {
    return (
      this.roles.has('BasicUser')
    );
  }

  isAdmin(): boolean {
    return this.roles.has('Admin');
  }

  isAuditor(): boolean {
    return this.roles.has('Auditor');
  }

  isSharedService(): boolean {
    return this.roles.has('SharedService');
  }

  isAdminOrAuditor(): boolean {
    return this.isAdmin() || this.isAuditor();
  }
}
