<div class="d-flex flex-row align-items-center">
    <i class="fa fa-star ibtn fa-solid text-warning cursor-pointer" title="Use favorite account" (click)="onFavClick()"></i>
    <span class="fa-stack fa-solid ibtn cursor-pointer" title="Add to favorite accounts" (click)="onAddFavClick()">
      <i class="fa fa-star fa-stack-1x text-warning"></i>
      <i class="fa fa-plus fa-stack-1x text-success"></i>
    </span>

  <div class="col-10 px-1">
    <uiowa-mfk-input [(mfk)]="mfk" (mfkChange)="onChange($event)" class="col-12"></uiowa-mfk-input>
  </div>
</div>

<ng-template #favAccntsDlg>
  <div class="vh-50">
    <lib-fav-accnts (OnSelect)="onFavSelect($event)" [newFavorite]="newFavorite" class="h-100"></lib-fav-accnts>
  </div>
</ng-template>