<div style="text-align: right; position: absolute; right:1em; margin-top: 2px;">
  <icon-text [faicon]="'envelope-open'" [text]="text(counts.new)" [color]="'orange'" title="New expenses" class="mx-1" [textClass]="textClass(counts.new)" (click)="navigate('new')"></icon-text>
  <icon-text [faicon]="'envelope-open'" [text]="text(counts.pending)" [color]="'deepskyblue'" title="Pending expenses" class="mx-1" [textClass]="textClass(counts.pending)" (click)="navigate('pending')"></icon-text>
  <icon-text [faicon]="'envelope-open'" [text]="text(counts.reconciled)" [color]="'springgreen'" title="Reconciled expenses" class="mx-1" [textClass]="textClass(counts.reconciled)" (click)="navigate('reconciled')"></icon-text>
  <icon-text [faicon]="'envelope-open'" [text]="text(counts.inError)" [color]="'red'" title="Expenses in error" class="mx-1" [textClass]="textClass(counts.inError)" (click)="navigate('inerror')"></icon-text>
  <!-- <icon-text [faicon]="'envelope-open'" [text]="text(counts.completed)" [color]="'mediumpurple'" title="Posted expenses" class="mx-1" [textClass]="textClass(counts.completed)" (click)="navigate('completed')"></icon-text> -->
  <span class="dropstart">
  <icon-text [faicon]="'message'" [text]="text(unreadNtfctns)" [title]="ntitle()" class="mx-1" [textClass]="textClass(notifications)" [iconClass]="'fa-regular'" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"></icon-text>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
      <li class="dropdown-item cursor-pointer fw-bold" [routerLink]="'/n10ns'" >View all notifications in a long list</li>
      <li><hr class="dropdown-divider"></li>
      <li *ngFor="let n of notifications"><a class="dropdown-item cursor-pointer" [class]="ntfctnClass(n)" [routerLink]="'/n10ns/'+n.id">{{n.subject}}</a></li>
    </ul>

  </span>
</div>