import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinidashComponent } from './minidash.component';
import { SharedModule } from '../shared';
import { ExpensesService, Util } from '../core';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    MinidashComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports:[
    MinidashComponent
  ],
  providers: [
    ExpensesService,
    Util
  ]
})
export class MinidashModule { }
