import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { CompleterData, CompleterItem } from "@vldmr-bus/ng-completer";
import { Person } from "src/app/core/models/person";

export class PersonData extends Subject<CompleterItem[]> implements CompleterData {
  constructor(private searcher: (string) => Observable<Person[]>) {
    super();
  }
  public search(term: string): void {
    this.searcher(term)
      .pipe(
        map((data: Person[]) => {
          const matches = (data)
            .map((card) => this.convertToItem(card)) as CompleterItem[];
          this.next(matches);
        })
      )
      .subscribe();
  }

  public cancel() {
    // Handle cancel
  }

  public convertToItem(person: Person): CompleterItem | null {
    if (!person) {
      return null;
    }
    // data will be string if an initial value is set
    return {
      title: `${person.name} (${person.loginId||person.address})`,
      originalObject: person,

    } as CompleterItem;
  }
}
