import { Util } from "../util/util";

// $$Implements SW.BE.DTO.ATT
export class Attachment {
  id: number = 0;
  filename: string = "";
  comment: string = "";
  entityId: number = 0;
  deleted: boolean = false;
  creator: string = "";
  createdOn: Date = new Date();
  modifiedBy?: string = "";
  modifiedOn?: Date = new Date();
  editable?: boolean = false;
  hasMenu?: boolean;
  selected?: boolean;
  tags?: string[];

  static readonly Dates: string[] = Util.DatePropertiesKeys(new Attachment());
  static fixDates(c: Attachment) {
    return Util.FixDates(c, Attachment.Dates);
  }
  static fromJson(c: Attachment) {
    return Object.assign(new Attachment(), Util.FixDates(c, Attachment.Dates));
  }

  public get isUsed():boolean {
    return this.tags?.some(t=>t=="used");
  }
}
