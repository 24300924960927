import { Injectable } from '@angular/core';
import { Person } from '../models/person';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UssService {
  constructor(private readonly http: HttpClient) { }

  getReps(): Observable<Person[]> {
    return this.http.get<Person[]>("api/uss/reps");
  }
}