import { Component, OnInit, OnDestroy } from '@angular/core';
import { InternalRoute, ExternalLink, HeaderUser, BannerLinks } from '@uiowa/uiowa-header';
import { ApplicationUser, UserService, ImpersonationService } from './core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  applicationName = 'ProTrav';
  externalLinks:BannerLinks = {
    link1: new ExternalLink('Employee Self Service', 'https://hris.uiowa.edu/portal18')
};
  user: HeaderUser;

  startTimer: boolean;
  private userSubscription: Subscription;
  testing = false;
  showMiniDash = true;
  hideMinidashRoutes = new Set<string>([
    'impersonation',
    'test-data'
  ])

  constructor(
    private readonly userService: UserService,
    private readonly impersonationService: ImpersonationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.userService.user$.subscribe(user => {
      this.user = <HeaderUser>{
        userName: user.loginId,
        originalUserName: user.originalUser
      };
      this.startTimer = user.isAuthenticated();
    });

    this.testing = !!this.cookieService.get('test');
    this.showMiniDash = !this.testing;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const title = this.getChildTitle(this.applicationName, this.activatedRoute.snapshot);
      this.titleService.setTitle(title);
      if (!this.testing) {
        let page = this.activatedRoute.snapshot.children.find(x => x !== undefined) ?.routeConfig.path;
        this.showMiniDash = !this.hideMinidashRoutes.has(page);
      }
    });  

  }

  getChildTitle(parentTitle: string, activatedRoute: ActivatedRouteSnapshot) {
    let title = activatedRoute.data.title;
    if (title === undefined) {
      let segments = activatedRoute.url.slice(-1);
      if (segments && segments.length)
        title = segments[0].path;
    } else if (typeof title == "function")
      title = title(activatedRoute);
    if (title)
      parentTitle += ":" + title;
    if (activatedRoute.firstChild?.firstChild) {
      return this.getChildTitle(parentTitle, activatedRoute.firstChild.firstChild);
    } else {
      return parentTitle;
    }
  }  

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  stopImpersonate() {
    this.impersonationService.stopImpersonate();
  }
}
