import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { HomeComponent } from './home/home.component';
import {
  RoleGuard,
  AccessDeniedComponent,
  ImpersonationComponent
} from './core';
import {
  faCreditCard as expenseMenuIcon,
  faFileLines as expReportMenuIcon,
  faStar as myLinksIcon,
  faSuitcase as myTravelIcon,
  faPlane as TripsIcon,
  faThumbsUp as myAuditIcon,
  faFolderOpen as myFilesIcon,
  faUserGear as adminIcon,
  faLayerGroup as cardAdminIcon,
  faMessage as cardAdminN10nsIcon,
  faTrowelBricks as N10TemplatesIcon,
  faUser as PrsnIcon,
  faExclamationTriangle as CntryBansIcon,
  faBuildingColumns as myMfksIcon
} from '@fortawesome/free-solid-svg-icons';
import { LayoutComponent } from './layout/layout.component';
export const routes: Routes = [
  {
    path: '',
    component:LayoutComponent,
    canActivate: [RoleGuard],
    data: { roles: ['BasicUser', 'SharedService', 'Admin', 'Auditor']},
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'my-links',
        loadChildren: () => import('./my-links/my-links.module').then(m => m.MyLinksModule),
        data: {
          label: "My Links",
          title: "My Links",
          icon: myLinksIcon,
        }
      },
      { // $$Implements SW.FE.Menu, SW.FE.XP.PAGE
        path: 'xp',
        loadChildren: () => import('./expenses/expenses.module').then(m => m.ExpensesModule),
        data: {
          label: "My PCard",
          title: "My PCard",
          icon: expenseMenuIcon,
        }
      },
      {
        path: 'my-travel',
        loadChildren: () => import('./expenses/expenses.module').then(m => m.ExpensesModule),
        data: {
          label: "My Travel",
          title: "My Travel",
          icon: myTravelIcon,
        }
      },
      {
        path: 'xpr',
        loadChildren: () => import('./expreports/expreports.module').then(m => m.ExpreportsModule),
        data: {
          label: "Expense Reports",
          title: "Exp. Reports",
          icon: expReportMenuIcon,
        }
      },
      {
        path: 'trips',
        loadChildren: () => import('./trips/trips.module').then(m => m.TripsModule),
        data: {
          label: "My Trips",
          title: "My Trips",
          icon: TripsIcon,
        }
      },
      {
        path: 'my-audit',
        loadChildren: () => import('./my-audit/my-audit.module').then(m => m.MyAuditModule),
        canActivate: [RoleGuard],
        data: {
          label: "My Audit",
          title: "My Audit",
          icon: myAuditIcon,
          roles: ['Auditor','Admin']
        }
      },
      {
        path: 'my-files', // $$Implements SW.FE.Menu, SW.FE.HUB.PAGE
        loadChildren: () => import('./my-files/my-files.module').then(m => m.MyFilesModule),
        data: {
          label: "My Files",
          title: "My Files",
          icon: myFilesIcon,
        }
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [RoleGuard],
        data: {
          label: "Admin Tools",
          title: "Admin Tools",
          icon: adminIcon,
          roles: ['Admin']
        }
      },
      {
        path: 'card-admin',
        loadChildren: () => import('./card-admin/card-admin.module').then(m => m.CardAdminModule),
        canActivate: [RoleGuard],
        data: {
          label: "Card Manager",
          title: "Card Manager",
          icon: cardAdminIcon,
          roles: ['Admin']
        }
      },
      {
        path: 'my-mfks',
        loadChildren: () => import('./my-mfks/my-mfks.module').then(m => m.MyMfksModule),
        data: {
          label: "My MFKs",
          title: "My MFKs",
          icon: myMfksIcon,
        }
      },
      {
        path: 'xpdtl/:id',
        pathMatch: 'full',
        loadChildren: () => import('./exp-dtl/exp-dtl.module').then(m => m.ExpDtlModule),
        data: {
          title: (r: ActivatedRouteSnapshot) => `Expense:${r.url.slice(-1)[0]?.path}`
        }
      },
      {
        path: 'expr/:id',
        pathMatch: 'full',
        loadChildren: () => import('./expr/expr.module').then(m => m.ExprModule),
        data: {
          title: (r: ActivatedRouteSnapshot) => `Exp.Report:${r.url.slice(-1)[0]?.path}`
        }
      },
      {
        path: 'expr/efr/:id',
        pathMatch: 'full',
        loadChildren: () => import('./expr/expr.module').then(m => m.ExprModule),
        data: {
          title: (r: ActivatedRouteSnapshot) => `Exp.Report:${r.url.slice(-1)[0]?.path}`
        }
      },
      {
        path: 'trip/:id',
        pathMatch: 'full',
        loadChildren: () => import('./trip/trip.module').then(m => m.TripModule),
        data: {
          title: (r: ActivatedRouteSnapshot) => `Trip:${r.url.slice(-1)[0]?.path}`
        }
      },
      { 
        path: 'n10ns', 
        pathMatch: 'full',
        loadChildren: () => import('./n10ns/n10ns.module').then(m => m.N10nsModule),
        data: {
          title:`Notifications`
        }
      },
      { 
        path: 'n10ns/:id', 
        pathMatch: 'full',
        loadChildren: () => import('./n10ns/n10ns.module').then(m => m.N10nsModule),
        data: {
          title: (r: ActivatedRouteSnapshot) => `Notification:${r.url.slice(-1)[0]?.path}`
        }
      },
      {
        path: 'n10ns-adm',
        pathMatch: 'full',
        loadChildren: () => import('./n10ns-admin/n10ns-admin.module').then(m => m.N10nsModule),
        canActivate: [RoleGuard],
        data: {
          label: "Notifications",
          title: `Admin:Notifications`,
          icon: cardAdminN10nsIcon,
          roles: ['Admin']
        }
      },
      {
        path: 'n10ns-adm/:id',
        pathMatch: 'full',
        loadChildren: () => import('./n10ns-admin/n10ns-admin.module').then(m => m.N10nsModule),
        canActivate: [RoleGuard],
        data: {
          title: (r: ActivatedRouteSnapshot) => `Admin:Notification:${r.url.slice(-1)[0]?.path}`,
          roles: ['Admin']
        },
      },
      {
        path: 'n10n-ts',
        loadChildren: () => import('./n10n-templates/n10n-templates.module').then(m => m.N10nTemplatesModule),
        data: {
          label: "Templates",
          title: (r: ActivatedRouteSnapshot) => `Notification Templates:${r.url.slice(-1)[0]?.path}`,
          icon: N10TemplatesIcon,
          roles: ['Admin'],
        }
      },
      {
        path: 'prsn/:id',
        loadChildren: () => import('./prsn/prsn.module').then(m => m.PrsnModule),
        data: {
          label: "Personal Data",
          title: (r: ActivatedRouteSnapshot) => `Personal data:${r.url.slice(-1)[0]?.path}`,
          icon: PrsnIcon,
          roles: ['SharedService', 'Admin', 'Auditor'],
        }
      },
      {
        path: 'passports',
        pathMatch: 'full',
        loadChildren: () => import('./passports/passports.module').then(m => m.PassportsModule),
        data: {
          title: `Passports`
        }
      },
      { 
        path: 'cntry-bans', 
        loadChildren: () => import('./cntry-bans/cntry-bans.module').then(m => m.CntryBansModule),
        data:{
          title: `Country Bans`,
          label: `Country Bans`,
          icon: CntryBansIcon,
          roles: ['Admin'],
        },
      },
    ]
  },
  {
    path: 'impersonation',
    component: ImpersonationComponent,
    canActivate: [RoleGuard],
    data: { roles: ['Admin', 'WebMaster'] }
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  { path: 'test-data', loadChildren: () => import('./test-data/test-data.module').then(m => m.TestDataModule) },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
