import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ExpensesService, NotificationsService, UserService, Util } from "../core";
import { AppEventType } from "../core/models/app-event";
import { AppEventsService } from "../core/services/app-events.service";
import { NotificationSubj } from "../core/models/notificationSubj";
import { Notification } from "rxjs";

class Counts {
  new?: number = undefined;
  pending?: number = undefined;
  reconciled?: number = undefined;
  inError?: number = undefined;
  //completed?: number = undefined;
}

@Component({
  selector: 'minidash',
  templateUrl: './minidash.component.html',
  styleUrls: ['./minidash.component.scss']
})
export class MinidashComponent implements OnInit {
  counts : Counts;
  unreadNtfctns:number;
  totalNtfctns = 0;
  notifications:NotificationSubj[];

  constructor(
    private router: Router,
    private xpSvc: ExpensesService,
    private aeBus: AppEventsService,
    private readonly userService: UserService,
    private readonly notificationService: NotificationsService,
  ){}

  ngOnInit(): void {
    this.aeBus.on(AppEventType.ExpenseStateChanged)
      .subscribe(e=>this.refresh());
    this.userService.user$.subscribe((u)=>{
      if (u?.id){
        this.refresh();
        this.refreshNotifications();
      }
    });
    this.xpSvc.counts$.subscribe(o=>{
      Object.keys(o).forEach(t => {
        this.counts[t]=o[t]
      });
    });
    this.notificationService.update$.subscribe(()=>{
      this.refreshNotifications();
    });
  }

  refresh() {
    this.counts = new Counts();
    this.xpSvc.getCounts(Object.keys(this.counts));
  }

  refreshNotifications() {
    this.notificationService.top().subscribe(
      res=>{
        this.unreadNtfctns = res.unread;
        this.totalNtfctns = res.total;
        this.notifications = res.notifications
      }
    )
  }

  text(i:any) {
    return (i == undefined) ?"⧖":i.toString();
  }

  ntitle() {
    return (!this.totalNtfctns) ? "No notificatios" : (!this.unreadNtfctns) ? `No unread notifications (${this.totalNtfctns} total` : `${this.unreadNtfctns} unread notification${(this.unreadNtfctns>1)?"s":""} out of ${this.totalNtfctns} total`;
  }

  textClass(i?:any):string {
    return (i == undefined) ? "flipping" : "";
  }

  ntfctnClass(n:NotificationSubj) {
      return (!n.viewedOn) ?"fw-bold":"";
  }

  navigate(target:string) {
    this.router.navigate(['/', 'xp', target]);
  }
  
}