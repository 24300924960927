import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkflowService, ICustomWindow, WindowRefService } from "../../services";

declare const formatWorkflowHistory: any;

interface WorkflowWidget {
    scopes: any[];
    environment: any;
    clientId: string;
    formId: number | string
}

@Component({
    selector: 'app-workflow',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent implements OnInit {

    @Input() workflowFormId: number;
    @Input() workflowPackageId: number;
    @Input() canView: boolean;
    @Input() showHistoryOnly: boolean;
    @Output() onPrint: EventEmitter<void> = new EventEmitter<void>();

    routingHistoryString: string = 'Loading...';
    showWorkflowWidget: boolean;
    showRoutingHistory: boolean;

    workflowJSSourceList: string[] = ['https://workflow.uiowa.edu/workflow-widget/workflow.js?ver=' + new Date().getTime()];

    workflowHistoryJSSourceList: string[] = [
        'https://workflow.uiowa.edu/workflow-widget/historyFormat.js?ver=' + new Date().getTime(),
        'https://workflow.uiowa.edu/workflow-widget/assets/js/workflow-widget-route-table.js?ver=' + new Date().getTime(),
        'https://code.jquery.com/jquery-3.6.0.min.js',
        'https://workflow.uiowa.edu/workflow-widget/assets/js/moment/moment.2.7.0.js',
    ];
    private _window: ICustomWindow;

    constructor(private readonly workflowService: WorkflowService,
                private readonly windowRef: WindowRefService) {

        this._window = windowRef.nativeWindow;
    }

    ngOnInit(): void {

        if (this.canView && !this.showHistoryOnly) {
            this.workflowWidget();
        } else {
            this.routingHistory();
        }
    }

    workflowWidget() {
        this.showWorkflowWidget = true;
        this.showRoutingHistory = false;

        this.workflowService.getWorkflowWidget(this.workflowFormId)
            .subscribe((workflowWidget: WorkflowWidget) => {
                this._window.WorkflowWidget = {
                    form_id: workflowWidget.formId,
                    package_id: this.workflowPackageId,
                    signature_id: '',
                    scope: workflowWidget.scopes[0],
                    client_id: workflowWidget.clientId,
                    environment: workflowWidget.environment,
                    post_sign_void: '',
                    post_version_mismatch: '',
                };

                // add js files dynamically
                this.workflowJSSourceList.forEach((src) => {
                    let node = document.createElement('script');
                    node.async = true;
                    node.src = src;
                    node.type = 'text/javascript';

                    document.getElementsByTagName('head')[0].appendChild(node);
                });
            });
    }

    routingHistory() {
        this.showWorkflowWidget = false;
        this.showRoutingHistory = true;

        // add js files dynamically
        this.workflowHistoryJSSourceList.forEach((src) => {
            let node = document.createElement('script');
            node.async = true;
            node.src = src;
            node.type = 'text/javascript';
            document.getElementsByTagName('head')[0].appendChild(node);
        });

        this.workflowService.getRoutingHistory(this.workflowFormId, this.workflowPackageId)
            .subscribe((routingHistory) => {
                setTimeout(() => {


                    // workflow history function that formats the JSON response
                    this.routingHistoryString = formatWorkflowHistory(routingHistory, this.workflowPackageId);

                    setTimeout(() => {

                        this.printParent();
                    }, 1000);
                }, 2000);
            });
    }

    private printParent() {
        this.onPrint.emit();
    }
}
