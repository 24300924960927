import { AddrCity } from "./addr-city";
import { Advisory } from "./advisory";
import { Change } from "./change";
import { Role } from "./role";

// $$Implements SW.BE.DTO.PERSON
export class Person implements AddrCity {
  name: string = "";
  firstName?: string = "";
  lastName?: string = "";
  middleName?: string = "";
  loginId?: string = "";
  univId?: string = "";
  emplId?: string = "";
  campusAddress?: string = "";
  campusPhone?: string = "";
  id?: number = 0;
  address?: string = "";
  department?: string = "";
  type?: string = "";
  email?: string;
  phone?: string;

  addr1?: string = "";
  addr2?: string;
  addr3?: string;
  zip?: string = "";

  roles?: Role[] = [];
  roleHistory?: Change[] = [];

  country: string;
  countryCode?: string;
  stateCode?: string;
  cityCode?: number;
  cityName: string ="";
  cityStateCountry: string;
  advisory?: Advisory;

  favorite?: boolean = false;

  static studentEmployeeTypes = new Set<string>(["Faculty/Staff and Student", "Student Employee"])
  public static isStudentEmployee(p:Person) {
    return Person.studentEmployeeTypes.has(p.type);
  }

  static fixDates(p:Person) {
    if (p.roles)
      p.roles.map(c => Role.fixDates(c));
    if (p.roleHistory)
      p.roleHistory.map(c => Change.fixDates(c));
    if (p.advisory)
      Advisory.fixDates(p.advisory);
    return p;
  }
}