<div class="d-flex flex-column h-100">
  <header>
    <div class="d-flex justify-content-between align-items-end">
      <h2 class="ms-2 m-0 text-primary ">
        <lib-fav-star *ngIf="person" [descr]="pageDescr"></lib-fav-star>
        {{pageDescr}}
      </h2>
    </div>
    <a *ngIf="showOthers" class="ms-2 text-primary cursor-pointer" (click)="selectPerson()">Click here to view favorites of other USS representative</a>
    <hr class="m-0" *ngIf="showOthers"/>
    <hr class="mb-0" *ngIf="!showOthers"/>
  </header>

  <section class="d-flex flex-shrink-0 justify-content-center">
    <div class="mx-auto" >
      <h4>Processes</h4>
      <ul>
        <li *ngFor="let l of links">
          <i class="fa-solid fa fa-star" title="Edit or delete" (click)="onLinkClick({$event,url:l.url})"></i><a
            [routerLink]="routerLink(l.url)" [queryParams]="routerQuery(l.url)">{{l.description}}</a>
        </li>
      </ul>
    </div>

    <div class="mx-auto" *ngIf="showPeople">
      <h4>People
        <sup>
          <i class="fa fa-circle-plus fa-sm cursor-pointer text-success" title="Add" (click)="onAddPerson()" *ngIf="myPplCnt>favPeople.length && !person"></i>
        </sup>
      </h4>
      <lib-people
        [people]="favPeople"
        [showRmFavs]="!person"
        (onRemovePerson)="onRemovePrsn($event)"
        (onAddPerson)="onFavPersonFromUnit($event)"
        [addTitle]="'Add to favorite'"
      ></lib-people>
    </div>

    <div class="mx-auto" *ngIf="showUnits">
      <h4>Units
        <sup>
          <i class="fa fa-list-check fa-sm cursor-pointer text-success" title="Select" (click)="onSelectFavUnits()"></i>
        </sup>
      </h4>
      <div class="h-100 overflow-auto" style="max-height: 80vh!important;">
          <lib-org-tree *ngFor="let u of favUnits | keyvalue"
            [orgTree]="$any(u.value)"
            [select]="false"
            [showFavUnits]="true"
            (onUnitExpand)="onUnitExpand($event, true)"
            (onUnitSelectChange)="onUnitSelectChange($event)"
            [showPeople]="true"
            [manageFavPeople]="!person"
            (onAddPerson)="onFavPersonFromUnit($event)"
            [addPersonTitle]="'Add to favorite'"
            (onRemovePerson)="onRemovePrsn($event)"
          ></lib-org-tree>
      </div>
    </div>

  </section>
</div>

<context-menu #favMenu>
  <ng-template contextMenuItem (execute)="editFav($any($event).item)">
    Edit
  </ng-template>
  <ng-template contextMenuItem (execute)="deleteFav($any($event).item)">
    Delete
  </ng-template>
</context-menu>

<ng-template #favEditTmpl>
  <ul style="max-height: 75vh; overflow: auto;">
    <label class="w-100">Description:
      <input type="text" [(ngModel)]="descr">
    </label>
  </ul>
</ng-template>

<ng-template #pplDlg>
  {{pplDlgData.text}}
    <lib-my-ppl-search [persons]="pplDlgData.ppl" [showLookup]="myPplCnt>99" (onSelected)="pplDlgData.person = $event"></lib-my-ppl-search>
</ng-template>

<ng-template #unitsDlg>
  <div class="container mb-1">
    <ul class="container w-100 overflow-auto" style="font-size: 13px; max-height: 50vh;">
      <li *ngFor="let u of myUnits.units | keyvalue">
        <lib-org-tree
          [orgTree]="u.value"
          [select]="true"
          [showFavUnits]="false"
          (onUnitExpand)="onUnitExpand($event, false)"
          (onUnitSelectChange)="onUnitSelectChange($event)"
          [showPeople]="false"
        ></lib-org-tree>
      </li>
    </ul>
  </div>
</ng-template>