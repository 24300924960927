import { Util } from "../util/util";
import { Attachment } from "./attachment";

export class HubFile extends Attachment {
  mfk: string;
  bjust: string;

  static fromJson(c: HubFile) {
    return Object.assign(new HubFile(), Util.FixDates(c, Attachment.Dates));
  }

}