import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';

@Injectable()
export class TestApiInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let clone:any;
    if (!!(<any>window).http_requests_log) {
      let http_requests_log: HttpRequest<unknown>[] = (<any>window).http_requests_log;
      clone = request.clone();
      if (clone.body instanceof FormData) {
        let body = {};
        for (let e of clone.body.entries())
          body[e[0]] = e[1];
        clone.body = body;
      }
      http_requests_log.push(clone);
    }

    return next.handle(request).pipe(
      tap({
        next:(event)=>{
          if (!clone)
            return;
          if (event instanceof HttpResponse)
            clone.response = event.clone();
        }
      })
    );
  }
}
