import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Dataset, DatasetCommand } from '../models/dataset';
import { Expense, Util } from '..';

import { XpReport } from '../models/xpreport';
import { AppEventsService } from './app-events.service';
import { AppEvent, AppEventType } from '../models/app-event';
import { XpDistribution } from '../models/xp-distribution';

@Injectable()
export class XpReportsService {
    constructor(
        private http: HttpClient,
        private aeBus: AppEventsService
    ) { } 

    // $$Implements SW.FE.XPR.PAGE, SW.FE.XPR.AUDIT.PAGE
    getAll(cmd: DatasetCommand): Observable<Dataset<XpReport>> {
        let params = Util.CommandToHttpParams(cmd);
        return this.http.get<Dataset<XpReport>>("api/xpreports/all", { params }).pipe(
            map(ds => {
                ds.data.forEach(r => XpReport.fixDates(r));
                return ds;
            })
        );
    }

    // $$Implements SW.FE.XPR.PAGE.DTL, SW.FE.XPR.PAGE.DTL.SRC, SW.FE.XPR.PAGE.DTL.SRC.EFR
    getXprDetails(xprid: number, efr?: boolean) : Observable<XpReport> {
        let url = efr ? `api/xpreports/efr/${xprid}` : `api/xpreports/${xprid}`;
        return this.http.get<XpReport>(url).pipe(
            map(r => {
                r.expenses = r.expenses.map(e=>{
                    e = Object.assign(new Expense(),e);
                    e.distributions = e.distributions.map(d=>Object.assign(new XpDistribution(),d));
                    return e;
                })
                return XpReport.fixDates(r);
            })
        );
    }

    // $$Implements SW.FE.XP.PAGE.RPRT_SBMT
    addExpenses(reportId:number, xpIds:number[]): Observable<number> {
        return this.http.put<number>(`api/xpreports/add`,{reportId,xpIds});
    }

    // $$Implements SW.FE.XPR.PAGE.DTL.XP.RM_ACT
    removeExpenses(reportId: number, xpIds: number[]): Observable<number> {
        return this.http.put<number>(`api/xpreports/rm`, { reportId, xpIds });
    }

    // $$Implements SW.FE.XPR.PAGE.DTL.DESC_UPDT
    updateDescription(reportId: number, desc: string): Observable<number> {
        return this.http.post<number>(`api/xpreports/desc`, Util.CommandToFormData({ reportId, desc }));
    }

    // $$Implements SW.FE.XPR.PAGE.DTL.WF_BTN_VLDT
    validateExpenses(reportId: number): Observable<string[]> {
      return this.http.get<string[]>("api/xpreports/validate/"+reportId);
    }

    // $$Implements SW.FE.XPR.PAGE.DTL.WF_SBMT
    submitToWF(reportId: number): Observable<number> {
      return this.http.post<number>("api/xpreports/wf/"+reportId,null).pipe(
        tap(
            r=>this.aeBus.dispatch(new AppEvent(AppEventType.ExpenseReportStateChanged,reportId))
        )
      );
    }

    passAudit(reportId: number): Observable<number> {
      return this.http.post<number>("api/xpreports/audit/"+reportId,null).pipe(
        tap(
            r=>this.aeBus.dispatch(new AppEvent(AppEventType.ExpenseReportStateChanged,reportId))
        )
      );
    }

    // $$Implements SW.FE.XPR.PAGE.DTL.ATT.ASSOC
    assocAtt(id: number, expenses: number[]): Observable<number> {
      return this.http.post<number>("api/xpreports/att/" + id, Util.CommandToFormData({ expenses }));
    }

    // $$Implements SW.FE.XPR.PAGE.DTL.CMNTS.CREATE.ACT
    addComment(xpid: number, comment: string, prvt: boolean): Observable<Object> {
        return this.http.post(`api/xpreports/${xpid}/cmnt`, { comment, prvt });
    }

    attachFromHub(xprid: number, fileid: number): Observable<number> {
        return this.http.post<number>(`api/xpreports/${xprid}/att/${fileid}`, null);
    }

    deleteFiles(ids: number[]): Observable<Object> {
        let params = Util.CommandToHttpParams({ ids });
        return this.http.delete(`api/person/att`, { params });
    }


    bulkUpdate(cmd: {
        acc: string,
        tripId: number,
        description: string,
        expIds: number[]
    }): Observable<Object> {
        return this.http.put(`api/expenses/bulk`, cmd);
    }

}