import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LoginService } from '@uiowa/uiowa-header';
import { Router } from '@angular/router';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private readonly loginService: LoginService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        var url = request.url;
        if (!url.toLowerCase().startsWith("http"))
          url = "https://" + url;
        if (err.status === 401 && !(new URL(window.location.origin + "/"+ request.url).pathname.startsWith("/api"))) {
          this.loginService.returnUri = this.router.url;
          this.loginService.login();
        }
        return throwError(err);
      })
    );
  }
}
