import { Util } from "../util/util";

// $$Implements SW.BE.DTO.FavAccount
export class FavAccount {
  id: number = 0;
  account: string = "";
  description: string = "";
  valid?: boolean = false;
  editor?: string = "";
  modifiedOn?: Date = new Date();
  static readonly Dates: string[] = Util.DatePropertiesKeys(new FavAccount());
  static fixDates(r: FavAccount) {
    return Util.FixDates(r, FavAccount.Dates);
  }
}

