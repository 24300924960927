<div class="border p-1 person-search">
  <div class="bg-success p-1">
    <ng-completer
      [(ngModel)]="selectedPerson"
      (selected)="onComplete($event)"
      [datasource]="personsData"
      [minSearchLength]="4"
      [inputClass]="'search-input'"
      [placeholder]="placeholder"
      [textSearching]="'Please wait...'"
      style="min-width: 100%;"
      autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
      (keydown) = "onKeyDown($event)"
    >
    </ng-completer>
    <label class="form-check-label w-100" *ngIf="showMyCB">
      <input type="checkbox" [(ngModel)]="myOnly" class="form-check-input mx-1">Search My People only
    </label>
  </div>
</div>

