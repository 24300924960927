import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, NgZone } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule, FavoritesService, PersonService, UserService } from './core';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { ConfigService } from './core/services/config.service';
import { MinidashModule } from './minidash/minidash.module';
import { MyLinksModule } from './my-links/my-links.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared';

// Uncomment to debug Zone Tasks affecting angular testability
// import 'zone.js/plugins/task-tracking'; 

export function appUserServiceFactory(userService: UserService) {
  return () => userService.getUser();
}

export function configServiceFactory(configService: ConfigService) {
  return () => configService.init();
}

export function favoritesServiceFactory(favoritesService: FavoritesService) {
  return () => favoritesService.init();
}

export function personServiceFactory(personService: PersonService) {
  return () => personService.init();
}

@NgModule({
  declarations: [AppComponent, HomeComponent, LayoutComponent],
  imports: [
    FontAwesomeModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    CoreModule,
    AppRoutingModule,
    MinidashModule,
    MyLinksModule,
    SharedModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appUserServiceFactory,
      deps: [UserService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configServiceFactory,
      deps: [ConfigService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: favoritesServiceFactory,
      deps: [FavoritesService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  /* 
  // Code to debug Zone Tasks affecting angular testability
  constructor(ngZone: NgZone) {
    const WAIT_SECONDS = 2;

    console.log(
      `⏳ ... Wait ${WAIT_SECONDS} seconds to dump pending tasks ... ⏳`
    );

    // Run the debugging `setTimeout` code outside of
    // the Angular Zone, so it's not considered as 
    // yet another pending Zone Task:
    ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        // Access the NgZone's internals - TaskTrackingZone:
        const TaskTrackingZone = (ngZone as any)._inner
          ._parent._properties.TaskTrackingZone;

        // Print to the console all pending tasks
        // (micro tasks, macro tasks and event listeners):
        console.debug('👀 Pending tasks in NgZone: 👀');
        console.debug({
          microTasks: TaskTrackingZone.getTasksFor('microTask'),
          macroTasks: TaskTrackingZone.getTasksFor('macroTask'),
          eventTasks: TaskTrackingZone.getTasksFor('eventTask'),
        });

        // Advice how to find the origin of Zone tasks:
        console.debug(
          `👀 For every pending Zone Task listed above investigate the stacktrace in the property 'creationLocation' 👆`
        );
      }, 1000 * WAIT_SECONDS);
    });
  }
  */

}
