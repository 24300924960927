import { Util } from "../util/util";

// $$Implements SW.BE.DTO.XP_DIST
export class XpDistribution {
  id: number = 0;
  xpid?: number = 0;
  creator?: string = "";
  createdOn?: Date = new Date();
  modifiedBy?: string = "";
  modifiedOn?: Date = new Date();
  description: string = "";
  amount: number = 0;
  account: string = "";
  slid: string;
  slac: string;

  static readonly Dates: string[] = Util.DatePropertiesKeys(new XpDistribution());
  static fixDates(c: XpDistribution) {
    return Util.FixDates(c, XpDistribution.Dates);
  }

  fullAcct() {
    return this.account + (this.slid ? ` ${this.slid}/${this.slac}` : "");
  }
}