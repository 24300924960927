import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FavLink } from "../models/fav-link";
import { UserService } from "./user.service";
import { Person } from "../models/person";
import { OrgUnit } from "../models/org-unit";
import { FavAccount } from "../models/fav-account";

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  constructor (
    private http: HttpClient,
    private readonly userService: UserService,
  ) {
    this.userService.user$.subscribe(user => {
      if (user.id && user.id != FavoritesService.userId) {
        FavoritesService.userId = user.id;
        this.init();
      }
    });
  }
  private static userId:number;
  private static _links: FavLink[];
  private static _urlMap:{[url:string]:FavLink} = {};
  get favorites(): FavLink[] {
    if (!FavoritesService._links)
      return [];
    return [...FavoritesService._links];
  }
  private static _myUnits = new BehaviorSubject<OrgUnit>(null);
  myUnits$: Observable<OrgUnit> = FavoritesService._myUnits.asObservable();
  private static _accounts = new BehaviorSubject<FavAccount[]>(null);
  accounts$: Observable<FavAccount[]> = FavoritesService._accounts.asObservable();

  init(): Promise<FavLink[]> {
    if (!FavoritesService.userId)
      return;
    this.updateMyUnits();

    this.updateAccounts();
    
    return this.http
      .get<FavLink[]>('api/favs/lnk')
      .toPromise()
      .then((a) => {
        FavoritesService._urlMap = {};
        let m = [];
        a.forEach(l => { 
            m.push(l);
            FavoritesService._urlMap[l.url]=l;
        });
        m.sort((x, y) => x.description.localeCompare(y.description));
        FavoritesService._links = m;
        return {...FavoritesService._links};
      })
      .catch(() => {
        FavoritesService._links = [];
        FavoritesService._urlMap = {};
        return { ...FavoritesService._links };
      });
  }

  
  userLinks(id:number):Observable<FavLink[]> {
    return this.http
      .get<FavLink[]>(`api/favs/lnk/${id}`)
      .pipe(map((a) => {
        let m = [...a];
        m.sort((x, y) => x.description.localeCompare(y.description));
        return m;
      })
    );
  }

  isUrlAdded(url:string): boolean {
    return FavoritesService._urlMap.hasOwnProperty(url);
  }

  getLink(url:string):FavLink {
    return FavoritesService._urlMap[url];
  }

  addLink(fav: FavLink): Observable<Object> {
    return this.http.post(`api/favs/lnk`, fav).pipe(
      tap(r=>{
        FavoritesService._links.push(fav);
        FavoritesService._links.sort((x, y) => x.description.localeCompare(y.description))
        FavoritesService._urlMap[fav.url]=fav;
      })
    );
  }

  updateLink(fav: FavLink): Observable<Object> {
    return this.http.put(`api/favs/lnk`, fav).pipe(
      tap(r=>{
        let l = FavoritesService._urlMap[fav.url];
        l.description = fav.description;
        FavoritesService._links.sort((x, y) => x.description.localeCompare(y.description))
      })
    );
  }

  delLink(fav: FavLink): Observable<Object> {
    return this.http.delete(`api/favs/lnk`, {body:fav}).pipe(
      tap(r=>{
        let l = FavoritesService._urlMap[fav.url];
        let a = FavoritesService._links;
        a.splice(a.indexOf(l),1);
        delete(FavoritesService._urlMap[fav.url]);
      })
    );
  }

  userPeople(id?: number): Observable<Person[]> {
    return this.http
      .get<Person[]>(`api/favs/ppl/${id||''}`)
      .pipe(map((a) => {
        let m = [...a];
        m.sort((x, y) => x.name.localeCompare(y.name));
        return m;
      })
      );
  }

  addPerson(id:number): Observable<Object> {
    return this.http.post(`api/favs/ppl/${id}`, null);
  }

  removePerson(id:number): Observable<Object> {
    return this.http.delete(`api/favs/ppl/${id}`);
  }

  updateMyUnits() {
    this.http.get<OrgUnit>('api/person/units')
      .toPromise()
      .then((units) => {
        FavoritesService._myUnits.next(units);
      });
  }

  userUnits(id?: number): Observable<OrgUnit> {
    return this.http.get<OrgUnit>(`api/person/units/${id || ''}`);
  }


  updateFavUnits(units: string[]): Observable<Object> {
    return this.http.put(`api/favs/units`,units);
  }

  updateAccounts() {
    return this.http.get<FavAccount[]>('api/favs/acc')
      .toPromise()
      .then((accounts) => {
        FavoritesService._accounts.next(accounts);
      });
  }

  addAccnt(fav: FavAccount): Observable<Object> {
    return this.http.post(`api/favs/acc`, fav);
  }

  updateAccnt(fav: FavAccount): Observable<Object> {
    return this.http.put(`api/favs/acc`, fav);
  }

  delAccnt(fav: FavAccount): Observable<Object> {
    return this.http.delete(`api/favs/acc`, { body: {id:fav.id} });
  }
}