import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type WorkflowEnvironment = 'test' | 'prod';

export interface WorkflowWidget {
    formId: number;
    scopes: string[];
    clientId: string;
    environment: WorkflowEnvironment;
}

export interface WorkflowAllowedActions{
    signatureId:      number;
    canAddApprover:   boolean;
    canSign:          boolean;
    canVoidAfter:     boolean;
    canView:          boolean;
    packageId:        number;
    canInitiatorVoid: boolean;
    canEdit:          boolean;
    canVoid:          boolean;
}

@Injectable({
    providedIn: "root",
})
export class WorkflowService {
    private readonly apiUrl = "api/wf";

    constructor(private readonly httpClient: HttpClient) {
    }

    getWorkflowWidget(formType: string | number): Observable<WorkflowWidget> {
        return this.httpClient.get<WorkflowWidget>(`${this.apiUrl}/${formType}/Widget/`);
    }


    getRoutingHistory(workflowFormId: number, workflowPackageId: number): Observable<string> {
        return this.httpClient.get<string>(`${this.apiUrl}/${workflowFormId}/RoutingHistory/${workflowPackageId}`)
    }
    
    getAllowedActions(workflowFormId:number, workflowPackageId: number): Observable<WorkflowAllowedActions> {
        return this.httpClient.get<WorkflowAllowedActions>(`${this.apiUrl}/${workflowFormId}/${workflowPackageId}/AllowedActions`)    
    }
}
