import { Util } from "../util/util";

export class Passport {
  id = 0;
  number = "";
  countryId = "";
  country = "";
  expiryDate = new Date();
  issueDate = new Date();

  static readonly Dates: string[] = Util.DatePropertiesKeys(new Passport());
  static fixDates(e: Passport) {
    return Util.FixDates(e, Passport.Dates);
  }

}