import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Params } from "@angular/router";
import { Person } from 'src/app/core/models/person';


@Component({
  selector: 'lib-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit {
  @Input() people: Person[];
  @Input() showAddFavs: boolean = false;
  @Input() showRmFavs: boolean = false;

  @Output() onRemovePerson = new EventEmitter<Person>();
  @Input() removeTitle: string = "Remove";
  @Output() onAddPerson = new EventEmitter<Person>();
  @Input() addTitle: string = "Add";

  constructor() { }

  ngOnInit(): void {
    // Initialization code goes here
  }

  routerLink(url: string): string {
    let l = new URL(window.location.origin + url);
    return l.pathname;
  }

  routerQuery(url: string): Params {
    let l = new URL(window.location.origin + url);
    let o: Params = {};
    for (let e of l.searchParams.entries()) {
      if (o.hasOwnProperty(e[0])) {
        if (!Array.isArray(o[e[0]]))
          o[e[0]] = [o[e[0]]];
        o[e[0]].push(e[1]);
      } else
        o[e[0]] = e[1];
    }
    return o;
  }

}