import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { 
  faTableCellsLarge as HomeIcon,
  faAngleDoubleRight as ExpandIcon,
  faAngleDoubleLeft as CollapseIcon 
} from '@fortawesome/free-solid-svg-icons';
import { routes } from '../app-routing.module';
import { UserService } from '../core';

const linkPaths = new Set<string>([
  "my-links",
  "xp", 
  "my-travel", 
  "xpr",
  "trips",
  "my-audit",
  "my-files",
  "admin",
  "card-admin",
  "my-mfks",
]);

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements AfterViewInit {
  homeIcon = HomeIcon;
  expandIcon = ExpandIcon;
  collapseIcon = CollapseIcon;
  // $$Implements SW.FE.Menu
  linkItems:Routes = [];
  expanded = false;
  constructor(
    private readonly userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    this.userService.user$.subscribe(u=>setTimeout(()=>{
      this.linkItems = routes[0].children.filter(r => 
        linkPaths.has(r.path) && (r.data?.roles?.some(r=>u.roles.has(r))??true));
    }))
    if (this.activatedRoute.snapshot.children.length == 0)
      this.router.navigate(['/', 'home']);
  }
}
