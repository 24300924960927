import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { Person } from "src/app/core/models/person";

@Component({
  selector: 'lib-my-ppl-search',
  templateUrl: "./my-ppl-search.component.html",
  styleUrls: ["./my-ppl-search.component.scss"]
})
export class MyPplSearchComponent implements OnInit {
  @Input() persons: Person[] = [];
  @Input() showLookup: boolean = false;
  @Output() onSelected: EventEmitter<Person> = new EventEmitter();

  loginId:string;
  filterFC = new FormControl();
  filter: RegExp;

  constructor(
  ) {

  }

  ngOnInit(): void {
    if (this.persons.length){
      this.loginId = this.persons[0].loginId;
      this.onSelect(this.persons[0]);
    }
    this.filterFC.valueChanges.pipe(
      debounceTime(500),
    ).subscribe({
      next: (v: string) => {
        this.filter = (!v)?null:new RegExp(v,'i')
      }
    });
  }

  isShown(p:Person):boolean {
    if (!this.filter)
      return true;
    return `${p.name} (${p.loginId})`.search(this.filter)>=0;
  }

  lookup(loginId:string) {
    let p = this.persons.find(p=>p.loginId==loginId);
    if (p)
      this.onSelect(p);
  }

  onSelect(p:Person) {
    this.onSelected.emit(p);
  }

}