<div class="container mb-1">
  <div *ngIf="persons && persons.length">
    <div class="input-group position-relative d-inline-flex align-items-center">
      <input type="text" [formControl]="filterFC" class="flex-fill focused-initially" placeholder="Filter by name" />
      <i class="fa fa-times position-absolute cursor-pointer" style="right: 10px;z-index: 100;" (click)="filterFC.reset()">
      </i>
    </div>
    <ul class="container w-100 overflow-auto" style="font-size: 13px; max-height: 50vh;">
      <ng-container *ngFor="let p of persons">
        <li *ngIf="isShown(p)" class="d-block" >
          <label class="col-12">
          <input class="form-check-input" type="radio" value="{{p.loginId}}" [(ngModel)]="loginId" (change)="lookup(p.loginId)">
          {{p.name}} ({{p.loginId}})
          </label>
        </li>
      </ng-container>
    </ul>
  </div>

  <lib-person-search *ngIf="showLookup" class="focused-initially" [kind]="'my'"  (onSelected)="onSelect($event)"></lib-person-search>
</div>
