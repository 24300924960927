import { Util } from "../util/util";

export class TripDestBan {
  id: number = 0;
  countryId: string = "";
  country: string = "";
  beginDate: Date = new Date();
  endDate: Date = new Date();
  description: string = "";

  static readonly Dates: string[] = Util.DatePropertiesKeys(new TripDestBan());

  static fixDates(e: TripDestBan) {
    return Util.FixDates(e, TripDestBan.Dates);
  }
}