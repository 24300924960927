import { Injectable } from "@angular/core";
import { ApplicationUser, PersonService, UserService } from "src/app/core";
import { ModalService } from "../../modal/modal.service";
import { Attachment } from "src/app/core/models/attachment";
import { Observable, Subscription, forkJoin, of } from "rxjs";
import { FormControl } from "@angular/forms";
import { debounceTime, switchMap } from "rxjs/operators";
import { HubSelectComponent } from "./hubSelect.component";
import { HubFile } from "src/app/core/models/HubFile";

@Injectable({ providedIn: 'root' })
export class HubSelectService {
  personId: number;
  personName: string;
  component: HubSelectComponent;
  onlyMine = false;
  myFiles: HubFile[] = [];
  myFilesAll: HubFile[] = [];
  otherFiles: HubFile[] = [];
  otherFilesAll: HubFile[] = [];
  loading = false;
  private userSubscription: Subscription;
  user: ApplicationUser;
  filterFC = new FormControl();
  filter: string;
  orderBy = "File name";
  orderDesc = false;
  deleteAfterAttach = false;
  selected: HubFile;

  constructor(
    private readonly userService: UserService,
    private prsnSvc: PersonService,
    private modal: ModalService,
  ) { 
    this.userSubscription = this.userService.user$.subscribe(user => {
      this.user = user;
      this.onlyMine = this.user?.id == this.personId;
    });
    this.filterFC.valueChanges.pipe(
      debounceTime(500),
    ).subscribe({
      next: (v: string) => {
        this.filter = v;
        this.selected = undefined;
        this.component.myFilesList?.unselect();
        this.component.otherFilesList?.unselect();
        this.filterAndSort();
      }
    });
  }

  registeComponent(component: HubSelectComponent) {
    this.component = component;
  }

  // $$Implements SW.FE.HUB.DLG
  get(personId: number,    personName: string):Observable<{ file: HubFile, rm: boolean }> {
    this.personId = personId;
    this.personName = personName;
    this.filterFC.reset();
    this.loading = true;
    this.selected = undefined;
    this.filter = undefined;
    this.myFiles = this.myFilesAll = this.otherFiles = this.otherFilesAll = undefined;
    let files = [this.prsnSvc.getMyFiles()];
    if (!this.onlyMine)
      files.push(this.prsnSvc.getFiles(this.personId))

    return forkJoin(files).pipe(
      switchMap(f => {
        this.loading = false;
        this.myFilesAll = f[0];
        this.otherFilesAll = (f.length > 1) ? f[1] : [];
        if (f.length == 0 || f.length == 1 && f[0].length == 0 || f.length == 2 && f[0].length == 0 && f[1].length == 0) {
          this.modal.notify("No files", "There are no files to select from. Please check your 'My files' page.")
            .subscribe();
          return of(false);
        }
        this.myFilesAll = f[0];
        if (f.length > 1)
          this.otherFilesAll = f[1];
        this.filterAndSort();
        return this.modal.show({
          title: "Select file to attach",
          cancelBtn: "Cancel",
          template: this.component.hubDlg,
          okBtnDisabled: () => !!this.okButtonHelp.apply(this), // $$Implements SW.FE.HUB.DLG.SELECT
          okBtnHelp: () => this.okButtonHelp.apply(this),
          elementClass: 'wide'
        })
      }),
      switchMap(
        res => of( res?{ file: this.selected, rm: this.deleteAfterAttach }:undefined) // $$Implements SW.FE.HUB.DLG.ACT
      )
    )
  }

  // $$Implements SW.FE.HUB.DLG.SELECT
  onSelect(i) {
    if (i < this.myFiles.length) {
      this.component.otherFilesList?.unselect();
      this.selected = this.myFiles[i];
    } else {
      this.component.myFilesList.unselect();
      this.selected = this.otherFiles[i - this.myFiles.length];
    }
  }

  // $$Implements SW.FE.HUB.DLG.SELECT
  okButtonHelp(): string {
    return (this.selected == undefined) ? "Please select a file to attach" : "";
  }

  ordering: { [key: string]: (a: Attachment, b: Attachment) => number } = {
    "File name": (a, b) => a.filename.localeCompare(b.filename),
    "Comment": (a, b) => (a.comment ?? "").localeCompare((b.comment ?? "")),
    "Updated by": (a, b) => (a.modifiedBy ?? "").localeCompare((b.modifiedBy ?? "")),
    "Date updated": (a, b) => ((+a.modifiedOn) ?? 0) - ((+b.modifiedOn) ?? 0),
  }

  get orderingNames(): string[] {
    return Object.keys(this.ordering);
  }

  setOrderBy(v: string) {
    this.orderBy = v;
    this.filterAndSort();
  }

  filterAndSort() {
    if (this.loading)
      return;

    let f = this.ordering[this.orderBy];
    let compareFunc = f;
    if (this.orderDesc)
      compareFunc = (a: Attachment, b: Attachment) => f(b, a);

    if (!this.filter) {
      this.myFiles = [...this.myFilesAll];
      this.myFiles.sort(compareFunc);
      this.otherFiles = [...this.otherFilesAll];
      this.otherFiles.sort(compareFunc);
      return;
    }
    let re = new RegExp(this.filter, "i");

    this.myFiles = this.myFilesAll.filter(f => re.test(f.filename) || re.test(f.comment ?? "") || re.test(f.creator) || re.test(f.modifiedBy ?? ""));
    this.myFiles.sort(compareFunc);

    this.otherFiles = this.otherFilesAll.filter(f => re.test(f.filename) || re.test(f.comment ?? "") || re.test(f.creator) || re.test(f.modifiedBy ?? ""));
    this.otherFiles.sort(compareFunc);
  }

  orderBtnClass() {
    return (this.orderDesc) ? "fa-arrow-down-wide-short" : "fa-arrow-down-short-wide";
  }

  toggleSort() {
    this.orderDesc = !this.orderDesc;
    this.filterAndSort();
  }

}