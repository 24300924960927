<uiowa-header
  [bannerLinks]="externalLinks"
  [applicationName]="applicationName"
  [user]="user"
  (stopImpersonation)="stopImpersonate()"
>
</uiowa-header>
<main role="main" id="content" class="w-100 p-0 flex-fill overflow-hidden">
  <minidash *ngIf="showMiniDash"></minidash>
  <router-outlet></router-outlet>
</main>
<footer role="contentinfo" style="z-index: 2000;">
  <app-footer></app-footer>
</footer>
