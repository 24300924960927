import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Dataset, DatasetCommand } from '../models/dataset';
import { AppEventsService, Util } from '..';

import { Expense } from '../models/expense';
import { XpDistribution } from '../models/xp-distribution';
import { AppEvent, AppEventType } from '../models/app-event';

@Injectable()
export class ExpensesService {
    constructor(
        private http: HttpClient,
        private aeBus: AppEventsService
    ) { } 

    // $$Implements SW.FE.XP.PAGE
    getAll(cmd: DatasetCommand): Observable<Dataset<Expense>> {
        let params = Util.CommandToHttpParams(cmd);
        return this.http.get<Dataset<Expense>>("api/expenses/all", { params }).pipe(
            map(ds => {
                ds.data.forEach(d => Expense.fixDates(d));
                return ds;
            })
        );
    }

    private _counts = new BehaviorSubject <object>(new Object());
    counts$: Observable<object> = this._counts.asObservable();

    getCounts(types:string[]) {
        types.forEach(t => {
            this.http.get<number>(`api/expenses/counts/${t}`)
                .subscribe(
                    v=>this._counts.next(Object.fromEntries([[t,v]])),
                    e => this._counts.next(Object.fromEntries([[t, NaN]]))
                )
        });
    }

    // $$Implements SW.FE.XP.PAGE.DTL
    getExpDetails(xpid: number) : Observable<Expense> {
        return this.http.get<Expense>(`api/expenses/${xpid}`).pipe(
            map(x => Expense.fixDates(x))
        );
    }

    // $$Implements SW.FE.PAGE.DTL.DIST_EDIT_SBMT
    putDistribution(xp: Expense, dist:XpDistribution):Observable<Object> {
        return this.http.put(`api/expenses/${xp.id}`, dist).pipe(
            tap( r => {
                    if (xp.distributions?.length == 0)
                        this.aeBus.dispatch(new AppEvent(AppEventType.ExpenseStateChanged, [xp.id]));
            })
        );
    }

    updateDistributions(expid:number, dists:XpDistribution[]): Observable<Object> {
        return this.http.put(`api/expenses/${expid}/dists`, dists);
    }


    // $$Implements SW.FE.XP.PAGE.DTL.DST_DEL
    delDistribution(id: number):Observable<Object> {
        return this.http.delete(`api/expenses/dist/${id}`);
    }

    delExpenseReportAttachment(id: number):Observable<Object> {
        return this.http.delete(`api/expenses/att/xpr/${id}`);
    }

    delExpenseAttachment(id: number):Observable<Object> {
        return this.http.delete(`api/expenses/att/${id}`);
    }

    attachFromHub(xpid: number, fileid: number): Observable<Object> {
        return this.http.post(`api/expenses/${xpid}/att/${fileid}`,null);
    }

    // $$Implements SW.FE.XP.PAGE.DTL.CMNTS.CREATE.ACT
    addComment(xpid: number, comment: string, prvt: boolean): Observable<number> {
        return this.http.post<number>(`api/expenses/${xpid}/cmnt`, {comment,prvt});
    }

    updateAttachmentComment(id: number, comment: string): Observable<Object> {
        return this.http.put(`api/expenses/att/${id}`, {comment});
    }
}