<!-- $$Implements SW.FE.ATT.UPLOAD -->
<div class="container mb-1" >
  <i class="fa fa-circle-info cursor-pointer float-start fs-4 position-relative" (click)="showTips()"
    title="Show tips for scanning" style="left: 8px; top:8px;"></i>
  <div class="border p-1" ngClass="{{dragAreaClass}}">
    <div class="row mb-1 ">
      <div class="col-9 col-sm-8 d-flex custom-file-button">
        <label class="input-group-text btn btn-sm btn-success" for="fileinput">Select or Drop File</label>
        <input type="file" #fileinput id="fileinput" class="form-control form-control-sm" (change)="selected($event)" />
      </div>
      <div class="col-3 col-sm-4 d-flex flex-row-reverse">
        <ng-content select="[otherSrc]"></ng-content>
        <button (click)="upload(fileinput)" [disabled]="this.getUploadBtnMsg()" [title]="this.getUploadBtnMsg()"
          class="btn btn-sm btn-success">
          Upload file
        </button>
      </div>
    </div>
    <div class="col-12 form-floating">
      <textarea class="form-control col-12" rows="1" placeholder="Description" [(ngModel)]="this.comment"></textarea>
      <label class="form-label" for="comment">Description:</label>
    </div>
    <div class="progress" *ngIf="progress">
      <div class="progress-bar" ng-style="{width : ( progress + '%' ) }"></div>
    </div>
  </div>

</div>

<ng-template #tipsDlg>
  <div class="container fs-6">
    <ul>
      <li>Black out Social Security #'s if they appear on any documentation.</li>
      <li>The last 4 digits of the credit card should be the only numbers visible on all receipts.</li>
      <li>Do not scan unnecessary documentation.</li>
      <li>Confidential information/documents should not be saved in a shared directory.</li>
      <li>Receipts should be retained by the department until the voucher has been loaded to General Ledger. Some
        departments may require you to keep your original receipts longer.</li>
      <li>Save files as PDF or Text file. Other types are allowable, but these are the preferred format.</li>
    </ul>
  </div>
</ng-template>