<div *ngIf="attachments&&attachments.length">
  <ul class="container w-100" style="font-size: 13px;">
    <li 
      class="row" 
      [class]="rowStyle(i)" 
      (click)="onRowClick(i)"
      *ngFor="let a of attachments; index as i"
    >
      <ng-container [ngTemplateOutlet]="attachmentLine" [ngTemplateOutletContext]="{a:a, i:i}"></ng-container>
    </li>
  </ul>
</div>
<div *ngIf="!(attachments&&attachments.length)" class="container w-100">
  No {{itemName}}s
</div>

<!-- $$Implements SW.FE.ATT.LIST.DELETED -->
<div *ngIf="deleted&&deleted.length">
  <b>Deleted {{itemName}}s:</b>
  <ul class="container w-100" style="font-size: 13px;">
    <li class="row" *ngFor="let a of deleted">
      <ng-container [ngTemplateOutlet]="attachmentLine" [ngTemplateOutletContext]="{a:a}"></ng-container>
    </li>
  </ul>
</div>

<!-- $$Implements SW.FE.ATT.LIST -->
<ng-template #attachmentLine let-a="a" let-i="i">
  <div class="col-4">
    <input type="checkbox" *ngIf="multiselect" [(ngModel)]="a.selected" (change)="onSelect.emit(i)"/>
    <a target="_blank" href="{{apiURL}}/{{a.id}}">{{a.filename}}</a>
  </div>
  <div class="col-8">
    <div *ngIf="modifiable && a.editable && !a.deleted" class="row">
      <textarea class="col-11" [rows]="commentRows(a)"
        (focus)="startEditComment($any($event.target).value)" 
        (blur)="updateComment(a.id,$event)"
        (keypress)="onKeyPress($event)"
      >{{a.comment}}</textarea>
      <div class="col-1">
        <a *ngIf="!a.hasMenu" (click)="onDeleteBtn(a.id)" class="btn btn-sm btn-danger float-end" data-loading-text="Deleting...">
          <i class="fa-solid fa-trash"></i>
        </a>
        <!-- $$Implements SW.FE.ATT.LIST_MN -->
        <a *ngIf="a.hasMenu" (click)="onMenu.emit({$event,id:a.id})" class="btn btn-sm btn-success float-end" data-loading-text="Deleting...">
          <i class="fa-solid fa-bars"></i>
        </a>
      </div>
    </div>
    <span *ngIf="!modifiable || !a.editable || a.deleted" class="text-pre">{{a.comment}}</span>
  </div>

  <ng-container *ngTemplateOutlet="extrasTemplate; context: {$implicit: a}"></ng-container>

  <div class="col-12" style="font-size: smaller"><i>Created by {{a.createdBy}} &#64;
      {{dateTimeString(a.createdOn)}}&nbsp;{{a.deleted?"Deleted":"Last updated"}} by {{a.modifiedBy}} &#64; {{dateTimeString(a.modifiedOn)}}</i>
    <hr />
  </div>
</ng-template>

<!-- $$Implements SW.FE.ATT.CMNT_HIST -->
<ng-template #cmntHistTmpl>
  <ul style="max-height: 75vh; overflow: auto;">
    <li *ngFor="let c of cmntHist">
      {{c.text}}<br>
      <span style="font-size: smaller"><i>By {{c.creator}}
          &#64; {{dateTimeString(c.createdOn)}}</i></span>
    </li>
  </ul>
</ng-template>