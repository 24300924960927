import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { forkJoin, of, Subscription } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { ApplicationUser, PersonService, UserService } from "src/app/core";
import { Attachment } from "src/app/core/models/attachment";
import { ModalService } from "../..";
import { AttachmentsComponent } from "../attachments/attachments.component";
import { HubSelectService } from "./hubSelect.service";

// $$Implements SW.FE.HUB.ATT
@Component({
  selector: 'lib-hubSelect',
  templateUrl: "./hubSelect.component.html",
  styleUrls: ["./hubSelect.component.scss"]
})
export class HubSelectComponent implements AfterViewInit {
  @Input() personId: number;
  @Input() personName: string;
  @Output() onSelected: EventEmitter<any> = new EventEmitter();
  @ViewChild('otherFilesList') otherFilesList: AttachmentsComponent;
  @ViewChild('myFilesList') myFilesList: AttachmentsComponent;
  @ViewChild("hubDlg") hubDlg: TemplateRef<any>;

  constructor(public svc:HubSelectService) {
  }

  ngAfterViewInit(): void {
    this.svc.registeComponent(this);
  }

}