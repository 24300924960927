import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PersonData } from "./person-data";
import { CompleterItem } from "@vldmr-bus/ng-completer";
import { Person } from "src/app/core/models/person";
import { PersonService } from "src/app/core";
import { Observable } from "rxjs";

@Component({
  selector: 'lib-person-search',
  templateUrl: "./person-search.component.html",
  styleUrls: [ "./person-search.component.scss"]
})
export class PersonSearchComponent implements OnInit {
  @Input() kind:"associate"|"visitor"|"my";
  @Input() showMyCB:boolean = false;
  @Input() placeholder: string = 'Find person by name or HawkID';
  @Output() onSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    private elRef: ElementRef,
    private prsnSvc: PersonService
  ) {

  }

  myOnly: boolean = false;

  search:(qry:string)=>Observable<Person[]> = qry=> {
    switch (this.kind) {
      case "associate":
        if (this.myOnly)
        return this.prsnSvc.searchMyPeople.call(this.prsnSvc, qry);
      else
        return this.prsnSvc.search.call(this.prsnSvc, qry);
      case "my":
        return this.prsnSvc.searchMyPeople.call(this.prsnSvc, qry);
      case "visitor":
        return this.prsnSvc.searchVisitors.call(this.prsnSvc, qry);
    }

  }

  ngOnInit(): void {
    this.personsData = new PersonData(this.search)
  }

  selectedPerson: any;
  personsData: PersonData;

  onComplete(selected: CompleterItem) {
    let p = selected.originalObject as Person;
    this.onSelected.emit(p);
    (<any>document.activeElement).blur();
  }

  onKeyDown(e:KeyboardEvent) {
    if (e.key == "Escape") {
      (<any>document.activeElement).blur();
      setTimeout(()=>this.elRef.nativeElement.parentElement.dispatchEvent(e));
    }
  }
}
