import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Comment } from 'src/app/core/models/comment';
import { Attachment } from '../models/attachment';
import { Util } from '../util/util';
import { Person } from '../models/person';
import { UserService } from './user.service';
import { HubFile } from '../models/HubFile';
import { Passport } from '../models/passport';
import { Description } from '../models/description';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  constructor(
    private readonly http: HttpClient,
    private readonly userService: UserService,
  ) {
    this.userService.user$.subscribe(user => {
      if (user.id && user.id != PersonService.userId) {
        PersonService.userId = user.id;
        this.init();
      }
    });
  }

  private static userId: number;
  private static _myPplCnt = new BehaviorSubject<number>(0);
  myPplCnt$: Observable<number> = PersonService._myPplCnt.asObservable();
  private static _allMyPpl = new BehaviorSubject<Person[]>([]);
  allMyPpl$: Observable<Person[]> = PersonService._allMyPpl.asObservable();

  init(): Promise<number> {
    PersonService._allMyPpl.next([]);
    PersonService._myPplCnt.next(0);
    if (!PersonService.userId)
      return;
    this.updateMyPplCnt();

  }

  // $$Implements SW.FE.XP.PAGE.DTL.PRSN_CMNT.API
  getComments(id: number): Observable<Comment[]> {
    return this.http.get<Comment[]>(`api/person/cmnt/${id}`).pipe(
      map(cmnts => {
        cmnts.forEach(c=>Comment.fixDates(c));
        return cmnts;
      })
    );
  }

  addComment(id: number, comment: string): Observable<Object> {
    return this.http.post(`api/person/${id}/cmnt`, { comment });
  }

  // $$Implements SW.FE.XP.PAGE.DTL.PRSN_CMNT.DEL
  deleteComment(id:number):Observable<Object> {
    return this.http.delete(`api/person/cmnt/${id}`);
  }

  // $$Implements SW.FE.HUB.PAGE.LIST
  getMyFiles():Observable<HubFile[]> {
    return this.http.get<HubFile[]>('api/person/att').pipe(
      map(files => {
        return files.map(f => HubFile.fromJson(f));
      })
    );
  }

  // $$Implements SW.FE.HUB.DLG
  getFiles(id:number):Observable<HubFile[]> {
    return this.http.get<HubFile[]>(`api/person/${id}/att`).pipe(
      map(files => {
        files.forEach(f => Attachment.fixDates(f));
        return files;
      })
    );
  }

  deleteFiles(ids:number[]):Observable<Object>{
    let params = Util.CommandToHttpParams({ids});
    return this.http.delete(`api/person/att`,{params});
  }

  copyFile(id:number,loginId:string):Observable<Object>{
    let params = Util.CommandToHttpParams({id,loginId});
    return this.http.post(`api/person/att/cp`,params);
  }

  moveFile(id:number,loginId:string):Observable<Object>{
    let params = Util.CommandToHttpParams({id,loginId});
    return this.http.post(`api/person/att/mv`,params);
  }

  renameFile(id:number,name:string):Observable<Object>{
    let params = Util.CommandToHttpParams({id,name});
    return this.http.put(`api/person/att/nm`,params);
  }

  updateFileExtras(f:HubFile):Observable<Object>{
    return this.http.put(`api/person/att/xtrs`,{id:f.id,mfk:f.mfk,bjust:f.bjust});
  }

  search(qry: string): Observable<Person[]> {
    let params = new HttpParams()
      .append("qry", qry);
    return this.http.get<Person[]>("api/person/search", { params });
  }

  lookup(loginId?:string): Observable<Person> {
    let params = new HttpParams();
    if (loginId)
      params = params.append("loginId", loginId);
    return this.http.get<Person>("api/person/lookup", { params });
  }

  searchMyPeople(qry: string): Observable<Person[]> {
    let params = new HttpParams().append("qry", qry);
    return this.http.get<Person[]>("api/person/my", { params });
  }

  searchVisitors(qry: string): Observable<Person[]> {
    let params = new HttpParams().append("qry", qry);
    return this.http.get<Person[]>("api/person/visitors", { params });
  }

  getMyPeople(): Observable<Person[]> {
    return this.http.get<Person[]>("api/person/my");
  }

  getPeople(accessScope:string) {
    let params = new HttpParams().append("ac", accessScope);
    return this.http.get<Person[]>("api/person/unit", {params});
  }

  updateMyPplCnt() {
    this.http.get<number>('api/person/cnt')
      .toPromise()
      .then((cnt) => {
        PersonService._myPplCnt.next(cnt);
        if (cnt > 0 && cnt < 100) {
          this.getMyPeople().subscribe(
            ppl => { PersonService._allMyPpl.next(ppl); }
          );
        }

      });
  }

  getPerson(id: number): Observable<Person> {
    return this.http.get<Person>(`api/person/${id}`);
  }

  getRolesDesc(): Observable<Map<number,Description>> {
    return this.http.get<{ [key: number]: Description }>("api/person/roles").pipe(
      map(roles => {
        let map = new Map<number,Description>();
        for (let key in roles) {
          map.set(+key, roles[key]);
        }
        return map;
      })
    );
  }

  getAssociate(loginId: string): Observable<Person> {
    return this.http.get<Person>(`api/person/assoc/${loginId}`).pipe(
      map(p => Person.fixDates(p))
    );
  }

  addGlobalRoles(loginId: string, roles: number[]): Observable<Object> {
    return this.http.post(`api/person/roles/${loginId}`, roles);
  }

  addAccScopeRoles(loginId: string, roleId: number, scopes: string[]): Observable<Object> {
    return this.http.post(`api/person/roles/${loginId}/${roleId}`, scopes);
  }

  removeRole(roleId: number): Observable<Object> {
    return this.http.delete(`api/person/roles/${roleId}`);
  }

  createVisitor(visitor: Person): Observable<number> {
    return this.http.post<number>("api/person", visitor);
  }

  updateVisitor(visitor: Person): Observable<number> {
    return this.http.put("api/person", visitor)
      .pipe(
        map(() => visitor.id)
      );
  }

  getPassports(id: number): Observable<Passport[]> {
    return this.http.get<Passport[]>(`api/person/${id}/ppt`).pipe(
      map(ps => ps.map(p => Passport.fixDates(p)))
    );
  }

  savePassport(id: number, ppt: Passport): Observable<Object> {
    if (ppt.id)
      return this.http.put(`api/person/ppt`, ppt);
    else
      return this.http.post(`api/person/${id}/ppt`, ppt);
  } 

  deletePassport(id: number): Observable<Object> {
    return this.http.delete(`api/person/ppt/${id}`);
  }
}
