import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Expense, Util } from 'src/app/core';
import { ModalService } from '../../modal/modal.service';
import { XpDistribution } from 'src/app/core/models/xp-distribution';

interface DistEdits {
  id: number;
  amount?: number,
  account?: string,
  desc?: string,
  slid?: string,
  slac?: string
}

@Component({
  selector: 'lib-distributions',
  templateUrl: './distributions.component.html',
  styleUrls: ['./distributions.component.scss']
})
export class DistributionsComponent implements AfterViewInit {
  @Input() expense: Expense;
  @Input() createButton: boolean = false;
  @Output() onEdit = new EventEmitter<Expense>();
  @Output() onSave = new EventEmitter<{ xp: Expense, dist: XpDistribution }>();
  @Output() onDelete = new EventEmitter<number>();
  @Output() instance = new EventEmitter<DistributionsComponent>();

  dateTimeString = Util.dateTimeString;
  currencyString = Util.currencyString;

  ded: DistEdits;
  ided: DistEdits;

  constructor(
    private modal: ModalService,
  ) {}

  ngAfterViewInit(): void {
    this.instance.emit(this);
  }

  // $$Implements SW.FE.XP.PAGE.DTL.ADD_DIST.ACT
  createDistribution() {
    if (!this.expense.editable)
      return;
    if (this.ded?.id === 0)
      return;
    if (this.checkEdits(() => this.createDistribution()))
      return;
    // $$Implements SW.FE.PAGE.DTL.DIST_EDIT
    this.ded = {
      id: 0,
      amount: this.expense.amount - this.expense.reconciled,
      account: this.expense.defaultAcc,
      desc: "",
      slid: "",
      slac: ""
    };
    this.ided = JSON.parse(JSON.stringify(this.ded));
  }

  // $$Implements SW.FE.PAGE.DTL.UPDT_DIST
  editDist(id: number) {
    if (!this.expense.editable)
      return;
    let dist = this.expense.distributions.find(d => d.id == id);
    if (!dist) {
      this.modal.error("Distriution not found").subscribe();
      return;
    }
    if (this.ded?.id === id)
      return;
    if (this.checkEdits(() => this.editDist(id)))
      return;
    // $$Implements SW.FE.PAGE.DTL.DIST_EDIT
    this.ded = {
      id: dist.id,
      amount: dist.amount,
      account: dist.account,
      desc: dist.description,
      slid: dist.slid,
      slac: dist.slac
    };
    this.ided = JSON.parse(JSON.stringify(this.ded));
    this.onEdit.emit(this.expense);
  }

  hasUnsavedChanges() {
    if (!this.ded)
      return false;
    return !Util.objectsEqual(this.ded, this.ided);
  }

  checkEdits(cb: () => void): boolean {
    if (!this.hasUnsavedChanges())
      return false;
    this.modal.ask("Unsaved changes", "You have not saved distribution edits. Would you like to proceed and lose edits?")
      .subscribe(res => {
        if (res) {
          this.ded = this.ided = undefined;
          cb();
        }
      });
    return true;
  }

  closeDistEditor(save: boolean) {
    if (save) {
      // $$Implements SW.FE.PAGE.DTL.DIST_EDIT_SBMT
      this.onSave.emit({
        xp:this.expense, 
        dist: Object.assign(new XpDistribution(),{
          id: this.ded.id,
          amount: this.ded.amount,
          account: this.ded.account,
          description: this.ded.desc,
          slid: this.ded.slid,
          slac: this.ded.slac
        })
      });
    }
    this.ded = undefined;
    this.onEdit.emit(null);
  }

  okButtonHelp(): string {
    if (!this.ded.desc || this.ded.desc.length < 3)
      return "Description is required";
    return "";
  }

  // $$Implements SW.FE.XP.PAGE.DTL.DST_DEL
  delDist(id: number) {
    if (!this.expense.editable)
      return;
    let dist = this.expense.distributions.find(d => d.id == id);
    if (!dist) {
      this.modal.error("Distriution not found").subscribe();
      return;
    }
    this.modal.show({
      title: "Remove distribution",
      cancelBtn: "Cancel",
      message: `Remove distribution '${dist.description}'`
    })
    .subscribe(
      res => {
        if (res)
          this.onDelete.emit(id);
      },
    )

  }

  discardChanges() {
    this.ded = this.ided = undefined;
  }

  getHeight() {
    let h = this.expense.distributions.length*85;
    if (this.ded)
      h+=180;
    return h;
  }
}