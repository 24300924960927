import { Util } from "../util/util";

export class TripPersonalDate {
  id: number = 0;
  begin: Date = new Date();
  end: Date = new Date();

  static readonly Dates: string[] = Util.DatePropertiesKeys(new TripPersonalDate());
  static fixDates(c: TripPersonalDate) {
    return Util.FixDates(c, TripPersonalDate.Dates);
  }

  static compare = (a:TripPersonalDate, b:TripPersonalDate) => (a.begin > b.begin) ? 1 : (a.begin < b.begin) ? -1 : (a.end > b.end) ? 1 : (a.end < b.end) ? -1 : 0
}
