import { AfterViewInit, Component} from '@angular/core';
import { routes } from '../app-routing.module';
import { TestingService, UserService } from '../core';
import { Route, Routes } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  constructor(
    private readonly userService: UserService,
    private testingService: TestingService
  ) {}
  get testing() {
    return this.testingService.testing;
  }

  readonly linkPaths: string[] = [
    "my-links",
    "xp",
    "my-travel",
    //"xpr",
    "my-audit",
    "my-files",
    "admin",
    "card-admin",
    "my-mfks",
  ];
  routes = routes[0].children;

}
