import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Expense, ExpensesService, FavoritesService, Util } from "src/app/core";
import { FavAccount } from "src/app/core/models/fav-account";
import { XpDistribution } from "src/app/core/models/xp-distribution";

@Component({
  selector: "lib-exp-editor",
  templateUrl: "./exp-editor.component.html",
  styleUrls: ["./exp-editor.component.css"],
})
export class ExpEditor implements OnInit, OnChanges {
  @Input() expense: Expense;
  @Output() onAddComment = new EventEmitter<number>();

  dateString = Util.dateString;
  currencyString = Util.currencyString;

  favAccounts: FavAccount[];
  draftAcct: string;
  draftJustOptnl: string;
  applyOptnlJust: boolean
  draftSlid: string;
  draftSlac: string;
  draftJustBulk: string;
  draftAmount:number;
  showComments = false;

  constructor(
    private favSvc: FavoritesService,
  ) {  }

  ngOnInit(): void {
    this.favSvc.accounts$.subscribe(accounts => setTimeout(() => this.favAccounts = [...accounts]));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.expense && changes.expense.currentValue) {
      if (!changes.expense.previousValue ) {
        this.showComments = false;
      }
      this.onCommentsUpdate();
    }
  }

  get balance() {
    return this.expense.amount - this.expense.distributions.reduce((a, b) => a + ((b.amount!==undefined)?b.amount:this.draftAmount), 0);
  }

  fullAcct(dist: XpDistribution) {
    return dist.account + (dist.slid?` ${dist.slid}/${dist.slac}`: "");
  }

  delDist(dist: XpDistribution) {
    this.expense.distributions = this.expense.distributions.filter(d => d !== dist);
  }

  editDist(dist: XpDistribution) {
    this.draftAcct = dist.account;
    this.draftSlid = dist.slid;
    this.draftSlac = dist.slac;
    this.draftJustOptnl = dist.description;
    this.expense.distributions = this.expense.distributions.filter(d => d !== dist);
    this.applyOptnlJust = true;
  }

  editAmount(dist: XpDistribution) {
    this.draftAmount = dist.amount;
    dist.amount = undefined;
    setTimeout(() => document.getElementsByClassName("amount-input")[0]["select"]());
  }

  onAmountKey($event: Event) {
    let event = $event as KeyboardEvent;
    if (event.key == "Enter")
      event.target["blur"]();
  }

  isAcctUsed(acct: string) {
    return this.expense.distributions.some(d => d.account == acct);
  }

  addDist(acct: string) {
    var dist = new XpDistribution();
    dist.account = acct;
    dist.slid = this.draftSlid;
    dist.slac = this.draftSlac;
    if (this.applyOptnlJust)
      dist.description = this.draftJustOptnl;
    this.expense.distributions.push(dist);
  }
  
  onFavSelected(e: Event) {
    var acct = (e.target as HTMLSelectElement).value;
    e.target["selectedIndex"] = 0;
    if (acct) {
      this.addDist(acct);
    }
  }

  applyJustBulk(all:boolean) {
    this.expense.distributions.forEach(d => {
      if (all || !d.description)
      d.description = this.draftJustBulk});
  }

  toggleComments() {
    if (this.expense.comments.length == 0)
      this.onAddComment.emit(this.expense.id);
    else
      this.showComments = !this.showComments;
  }

  onCommentsUpdate() {
    this.expense.comments = this.expense.comments.filter(c => !c.deleted);
    if (this.expense.comments.length == 0)
      this.showComments = false;
  }
}
