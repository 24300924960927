import { Util } from "../util/util";

// $$Implements SW.BE.DTO.N10N_SUBJ
export class NotificationSubj {
  id = 0;
  createdOn: Date = new Date();
  viewedOn?: Date = new Date();
  emailed?: boolean = false;
  deleted?: boolean = false;
  recipient?: string = "";
  subject = "";
  type?="";
  optOut?=false;
  canOptOut?=false;
  
  static readonly Dates: string[] = Util.DatePropertiesKeys(new NotificationSubj());
  static fixDates(e: NotificationSubj) {
    return Util.FixDates(e, NotificationSubj.Dates);
  }

}