import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ContextMenuComponent, ContextMenuService } from '@vldmr-bus/ngx-contextmenu';
import { FavoritesService, PersonService, UserService, UssService } from '../core';
import { FavLink } from '../core/models/fav-link';
import { ModalService } from '../shared';
import { Person } from '../core/models/person';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { OrgUnit, filterUnits, iterateUnits, reduceUnits } from '../core/models/org-unit';

@Component({
  selector: 'app-my-links',
  templateUrl: './my-links.component.html',
  styleUrls: ['./my-links.component.scss']
})
export class MyLinksComponent implements OnInit {
  links: FavLink[];
  showPeople = false;
  myPplCnt = 0;
  myPeople: Person[] =[];
  favPeople: Person[] = [];
  myUnits: OrgUnit;
  showUnits = false;
  favUnits:OrgUnit[] = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private contextMenuService: ContextMenuService,
    private modal: ModalService,
    private favSvc: FavoritesService,
    private readonly userService: UserService,
    private prsnSvc: PersonService,
    private ussSvc: UssService,
  ) { }

  person: Person;
  iAmSharedService = false;
  iAmAdmin = false;
  showOthers = false;
  ngOnInit(): void {
    this.links = this.favSvc.favorites;
    this.userService.user$.subscribe(user => {
      this.iAmAdmin = user.isAdmin();
      this.iAmSharedService = user.isSharedService();
      this.showOthers = this.iAmAdmin || this.iAmSharedService;
      this.myPplCnt = 0;
    });

    this.prsnSvc.myPplCnt$.subscribe(cnt=>{
      this.myPplCnt = cnt;
      this.showPeople = cnt > 0;
    });
    this.prsnSvc.allMyPpl$.subscribe(ppl => {
      this.myPeople = ppl;
    })

    this.favSvc.myUnits$.subscribe(orgTree=>this.unitsUpdated(orgTree));

    this.activatedRoute.params.subscribe(p => setTimeout(() => {
      let loginId = p["loginId"];
      if (!loginId) {
        setTimeout(() => {
          this.links = this.favSvc.favorites;
        });
        this.favSvc.userPeople().subscribe(
          ppl=>this.favPeople = ppl,
          e => this.modal.error(e).subscribe()
        );
        this.favSvc.updateMyUnits();
        return;
      } else {
        this.prsnSvc.lookup(loginId).subscribe(
          p => {
            this.person = p;
            this.favSvc.userLinks(p.id).subscribe(
              f=>this.links = f,
              e => this.modal.error(e).subscribe(() => this.router.navigate(["/my-links"]))
            );
            this.favSvc.userPeople(p.id).subscribe(
              ppl => this.favPeople = ppl,
              e => this.modal.error(e).subscribe(() => this.router.navigate(["/my-links"]))
            );
            this.favSvc.userUnits(p.id).subscribe(orgTree => this.unitsUpdated(orgTree));
          },
          e => this.modal.error(e).subscribe(() => this.router.navigate(["/my-links"]))
        );
      }
    }));
    
  }

  get pageDescr():string {
    return this.person ? `Favorites of ${this.person.name}` : 'My Favorites';
  }

  routerLink(url:string):string {
    let l = new URL(window.location.origin+url);
    return l.pathname;
  }

  routerQuery(url:string):Params {
    let l = new URL(window.location.origin+url);
    let o:Params = {};
    for (let e of l.searchParams.entries()) {
      if (o.hasOwnProperty(e[0])){
        if (!Array.isArray(o[e[0]]))
          o[e[0]] = [o[e[0]]];
        o[e[0]].push(e[1]);
      } else
        o[e[0]]=e[1];
    }
    return o;
  }

  @ViewChild('favMenu', { static: true }) public favMenu: ContextMenuComponent;
  onLinkClick(event: { $event: MouseEvent, url: string }) {
    if (this.person)
      return;
    this.contextMenuService.show.next({
      event: event.$event,
      contextMenu: this.favMenu,
      item: event.url,
      anchorElement: event.$event.currentTarget
    });
    event.$event.preventDefault();
    event.$event.stopPropagation();
  }

  @ViewChild("favEditTmpl") favEditTmpl: TemplateRef<any>;
  descr="";
  editFav(url:string) {
    if (this.person)
      return;
    let l = this.favSvc.getLink(url);
    this.descr = l.description;
    this.modal.show({
      title: "Edit favorite",
      cancelBtn: "Cancel",
      template: this.favEditTmpl
    }).subscribe(
      res => {
        if (!res)
          return;
        l.description = this.descr;
        this.favSvc.updateLink(l).subscribe(
          res => {
            this.links = this.favSvc.favorites;
          }
        );
      });
  }

  deleteFav(url:string) {
    if (this.person)
      return;
    let l = this.favSvc.getLink(url);
    this.modal.ask("Delete favorite", `Delete "${l.description}" from favorites?`)
    .subscribe(
      res => {
        if (!res)
          return;
        this.favSvc.delLink(l).subscribe(
          res => {
            this.links = this.favSvc.favorites;
          }
        );
      });
  }

  pplDlgData: {
    text?:string;
    ppl?:Person[];
    loginId?:string;
    person?: Person;
  } = {};

  @ViewChild("pplDlg") pplDlg: TemplateRef<any>;
  selectPerson() {
    this.ussSvc.getReps()
    .pipe(
      switchMap(reps=>{
        if (reps.length==0)
          return of(0);
        this.pplDlgData.ppl = reps;
        this.pplDlgData.loginId = reps[0].loginId
        this.pplDlgData.text = "Select a user whose favorites you'd like to view";
        return this.modal.show({
          title: "View other user's favorites",
          cancelBtn: "Cancel",
          template: this.pplDlg
        });
      })
    ).subscribe(
      res => {
        if (!res)
          return;
        this.router.navigate(['/my-links/' + this.pplDlgData.person.loginId]);
      },
      e => this.modal.error(e).subscribe()
    );
  }

  @ViewChild("pplSrchDlg") pplSrchDlg: TemplateRef<any>;
  onAddPerson() {
    if (this.person)
      return;
    this.pplDlgData.text = "Please select a person to add";
    if ((this.myPeople.length)) {
      this.pplDlgData.ppl = this.myPeople.filter(p => 0 > this.favPeople.findIndex(mp => mp.id == p.id));
      this.pplDlgData.loginId = this.pplDlgData.ppl[0].loginId;
    } else
      this.pplDlgData.ppl = [];
    this.modal.show({
      title: "Add a person",
      cancelBtn: "Cancel",
      template: this.pplDlg
    }).subscribe(
      res => {
        if (!res)
          return;
        this.favSvc.addPerson(this.pplDlgData.person.id).subscribe(
          ()=>{
            this.favSvc.userPeople().subscribe(
              ppl => this.favPeople = ppl,
              e => this.modal.error(e).subscribe()
            );
          },
          e => this.modal.error(e).subscribe()
        );
      },
      e => this.modal.error(e).subscribe()
    );
  }

  onRemovePrsn(p:Person) {
    if (this.person)
      return;
    this.modal.ask('Remove from favorites', `Remove ${p.name} from your favorites?`)
      .pipe(
        switchMap(res => {
          return res ?
            this.favSvc.removePerson(p.id)
            : of(0)
        })
      )
      .subscribe(
        res => {
          if (res !== 0)
            this.favSvc.userPeople().subscribe(
              ppl => {
                this.favPeople = ppl;
                this.favUnits.forEach(u=>iterateUnits(u,u=>u.people&&u.people.forEach(x=>{if(x.loginId==p.loginId)x.favorite=false})))
              },
              e => this.modal.error(e).subscribe()
            );
        },
        e => this.modal.error(e).subscribe()
      );
  }

  unitsUpdated(orgTree:OrgUnit) {
    this.myUnits = orgTree;
    this.showUnits = orgTree && orgTree.units && Object.keys(orgTree.units).length > 0;
    this.favUnits = (!orgTree?.units) ? [] :
      Object.values(orgTree.units).reduce(
        (p: OrgUnit[], c: OrgUnit) => {
          return p.concat(filterUnits(c, u => u.favorite));
        },
        []
      )
    this.favUnits.sort((x, y) => x.accessScope.localeCompare(y.accessScope));
  }

  @ViewChild("unitsDlg") unitsDlg: TemplateRef<any>;
  onSelectFavUnits() {
    if (this.person)
      return;
    let start = reduceUnits(this.myUnits, (p: string[], c) => { if (c.favorite) p.push(c.accessScope); return p; }, [])
      .sort((x, y) => x.localeCompare(y));
    this.modal.show({
      title: "Select favorite units",
      cancelBtn: "Cancel",
      template: this.unitsDlg
    })
    .subscribe(
      res => {
        if (!res) {
          let set = new Set<string>(start);
          iterateUnits(this.myUnits,u=>u.favorite = set.has(u.accessScope));
        }
        let sel = reduceUnits(this.myUnits,(p:string[],c)=>{if (c.favorite) p.push(c.accessScope);return p;},[])
          .sort((x, y) => x.localeCompare(y));
        if (sel.length == start.length && sel.every((s,i)=>s==start[i]))
          return;
        this.favSvc.updateFavUnits(sel).subscribe(
          () => {
            this.favSvc.updateMyUnits();
          },
          e => {
            let set = new Set<string>(start);
            iterateUnits(this.myUnits, u => u.favorite = set.has(u.accessScope));
            this.modal.error(e).subscribe();
          }
        );
      }
    );
  }

  onUnitSelectChange(u:OrgUnit) {
    if (!u.units)
      return;
    let v = u.favorite;
    iterateUnits(u,u=>u.favorite=v);
  }

  onUnitExpand(u:OrgUnit, loadPpl:boolean) {
    if (u.expanded && loadPpl && u.people===undefined) {
      this.prsnSvc.getPeople(u.accessScope).subscribe(
        ppl => {
          u.people=ppl;
          ppl.forEach(p=>p.favorite = this.favPeople.some(f=>f.loginId == p.loginId));
        },
        e => {
          u.people = [];
          this.modal.error(e).subscribe();
        }
      )
    }
  }

  onFavPersonFromUnit(p:Person) {
    if (this.person)
      return;
    this.modal.ask("Add a person", `Add ${p.name} to your favorite people?`).subscribe(
      res => {
        if (!res)
          return;
        this.favSvc.addPerson(p.id).subscribe(
          () => {
            this.favSvc.userPeople().subscribe(
              ppl => {
                this.favPeople = ppl;
                p.favorite = true;
              },
              e => this.modal.error(e).subscribe()
            );
          },
          e => this.modal.error(e).subscribe()
        );
      },
      e => this.modal.error(e).subscribe()
    );

  }
}
