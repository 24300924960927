import { Util } from "../util/util";

// $$Implements SW.BE.DTO.CHNG
export class Change {
  creator: string="";
  createdOn: Date= new Date();
  property: string = "";
  entity?: string = "";
  entityId?: number = 0;
  oldValue: string = "";
  newValue: string = "";

  static readonly Dates: string[] = Util.DatePropertiesKeys(new Change());
  static fixDates(c: Change) {
    return Util.FixDates(c, Change.Dates);
  }

  public static render(c:Change): string {
    let res = `${c.creator ?? "???"} on ${Util.dateTimeString(c.createdOn)} `;
    let prop = c.property + (c.entity ? ` of ${c.entity}` : '');
    if (c.oldValue && c.newValue)
      res += `changed <b>${prop}</b> from "<b>${c.oldValue}</b>" to "<b>${c.newValue}</b>"`
    else if (!c.oldValue && c.newValue)
      res += `added <b>${prop}</b>: "<b>${c.newValue}</b>"`
    else if (c.oldValue && !c.newValue)
      res += `deleted <b>${prop}</b>: "<b>${c.oldValue}</b>"`
    return res;
  }


}