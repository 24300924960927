import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PCard } from "../models/pcard";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class PCardService {

  constructor(
    private readonly http: HttpClient,
  ) {}

  search(qry: string): Observable<PCard[]> {
    let params = new HttpParams()
      .append("qry", qry);
    return this.http.get<PCard[]>("api/pcard/search", { params });
  }

}