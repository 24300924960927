import { Injectable } from '@angular/core';

// This interface is optional, showing how you can add strong typings for custom globals.
// Just use "Window" as the type if you don't have custom global stuff
export interface ICustomWindow extends Window {
    WorkflowWidget: { post_version_mismatch: string; signature_id: string; environment: any; scope: any; form_id: number | string; package_id: number; client_id: string; post_sign_void: string };
 
    __custom_global_stuff: string;
}

function getWindow(): any {
    return window;
}

@Injectable({providedIn: 'root'})
export class WindowRefService {
    get nativeWindow(): ICustomWindow {
        return getWindow();
    }

    scrollMainDivToTop() {
        var myDiv = document.getElementById('main');
        myDiv.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    };
}
