import { Util } from "../util/util";

export class StatusChange {
    id: number = 0;
    entityId: number = 0;
    statusId: number = 0;
    creator: string = "";
    createdOn: Date = new Date();
    modifiedBy?: string = "";
    modifiedOn?: Date = new Date();
    memo?: string = "";
    static readonly Dates: string[] = Util.DatePropertiesKeys(new StatusChange());
    static fixDates(c:StatusChange) {
        return Util.FixDates(c, StatusChange.Dates);
    }
}

