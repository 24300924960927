import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, Params } from '@angular/router';

export interface Attachable {
  OnAttach?: ()=>void;
}

@Injectable({
  providedIn: 'root',
})
export class AppRouteReuseStrategy extends RouteReuseStrategy {
  private savedHandles = new Map<string, DetachedRouteHandle>();

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const key = this.getRouteKey(route);
    //console.log(`retrieve ${key}`)
    let handle = this.savedHandles.get(key);
    if (handle) {
      let c = (handle as any).componentRef?.instance;
      if (c && c.OnAttach)
         setTimeout(()=>c.OnAttach());
    }
    return handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (!route.data.reuse)
      return false;
    let key = this.getRouteKey(route);
    let result =  this.savedHandles.has(key);
    //console.log(`shouldAttach ${key} : ${result}`)
    return result;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reuse;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    let result =  future.routeConfig === curr.routeConfig;
    //console.log(`Should reuse furure ${this.getRouteKey(future)} curr ${this.getRouteKey(future)}: ${result}`)
    return result
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    const key = this.getRouteKey(route);
    //console.log(`store ${key}`)
    const path = this.getRoutePath(route);
    for (const k of this.savedHandles.keys()) {
      if (k!=key && k.startsWith(path)) {
        const stored = this.savedHandles.get(k);
        if (stored==null || (handle as any).componentRef?.instance === (stored as any).componentRef?.instance)
          this.savedHandles.delete(k);
        else
          this.clearSavedHandle(k);
      }
    }
    this.savedHandles.set(key, handle);
  }

  getRoutePath(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.filter(u => u.url).map(u => u.url).join('/');
  }
  private getRouteKey(route: ActivatedRouteSnapshot): string { 
    var params:Params = {...route.queryParams};
    if (route.data.ignoreQPars)
      route.data.ignoreQPars.forEach(p=>delete(params[p]));
    return this.getRoutePath(route) +JSON.stringify(params);
  }

  public clearSavedHandle(key: string): void {
    //console.log('Delete '+key);
    const handle = this.savedHandles.get(key);
    if (handle) {
      (handle as any).componentRef.destroy();
    }

    this.savedHandles.delete(key);
  }
}
