import { Component, OnInit, Input, Injectable, ViewChild, ElementRef, Output, EventEmitter, HostListener, TemplateRef } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpEventType } from '@angular/common/http';
import { ModalService } from '../../modal/modal.service';
import { Util } from '../../../core';


@Component({
  selector: 'lib-upload',
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.scss"]
})
export class UploadComponent {
  @Input() uploadURL: string;
  @Input() title:string = "Upload file";
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();

  @ViewChild('fileinput') fileinput: ElementRef;

  progress: number;
  comment:string="";
  dragAreaClass: string;

  constructor(
    private http: HttpClient,
    private modalService: ModalService
  ) { }

  selected(event) {
    // noop -- need to have this call for reactivity
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      const dT = new DataTransfer();
      dT.items.add(event.dataTransfer.files[0]);
      this.fileinput.nativeElement.files = event.dataTransfer.files;
    }
  }

  getUploadBtnMsg() {
    let file = this.fileinput?.nativeElement?.files[0];
    if (!file)
      return "Please select file to upload";
    else return "";
  }
  upload(fileinput: HTMLInputElement) {
    if (!fileinput.files[0])
      return;
    // $$Implements SW.FE.ATT.UPLOAD_ACT
    let formData = new FormData();
    formData.append("description", this.comment);
    formData.append("file", fileinput.files[0], fileinput.files[0].name);
    let req = new HttpRequest('POST', this.uploadURL, formData, { reportProgress: true });
    this.http.request(req)
      .subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;

            case HttpEventType.UploadProgress:
              this.progress = Math.round(100 * event.loaded / event.total);
              break;

            // $$Implements SW.FE.ATT.UPLOAD_RES, SW.FE.ATT.UPLOAD_CALLBACK
            case HttpEventType.Response:
              this.progress = 0;
              this.comment = "";
              this.fileinput.nativeElement.value = null;
              this.onSuccess.emit(event.body);
              break;

            default:
              break;
          }
        },
        error => {
          this.error("Failed to upload file", error);
          this.progress = 0;
        }
      )
  }

  error(msg, err) {
    err = err || {};
    msg += ":<br>" + (Util.errorMessage(err) || "");
    this.modalService.notify("Error", msg).subscribe();
  }

  @ViewChild("tipsDlg") tipsDlg: TemplateRef<any>;
  showTips() {
    this.modalService.show({
      title:"Tips for scanning",
      okOnEnter:true,
      template: this.tipsDlg
    })
    .subscribe();
  }
}