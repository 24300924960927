import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {  HTTP_INTERCEPTORS } from '@angular/common/http';
import { UiowaHeaderModule } from '@uiowa/uiowa-header';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { ImpersonationComponent } from './components/impersonation/impersonation.component';
import { AppErrorHandler } from './services/app-errror-handler';
import { HttpCacheControlService } from './services/http-cache-control.service';
import { UnauthorizedInterceptor } from './services/unauthorized.interceptor';
import { TestApiInterceptor } from './services/testApi.interceptor';
import { AppRouteReuseStrategy } from './util/app-route-reuse-strategy';

@NgModule({
  imports: [
    CommonModule,
    UiowaHeaderModule,
    RouterModule,
    FormsModule,
    NgbModule,
  ],
  declarations: [
    AppFooterComponent,
    AccessDeniedComponent,
    ImpersonationComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TestApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCacheControlService,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy
    }
  ],
  exports: [
    UiowaHeaderModule,
    AppFooterComponent,
    AccessDeniedComponent,
    ImpersonationComponent,
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    core: CoreModule
  ) {
    if (core) {
      throw new Error('Core Module can only be imported in AppModule.');
    }
  }
}
