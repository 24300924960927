import { Util } from "../util/util";

export class LineItemSummary {
    shippingCost: number = 0;
    lineItemDetails: LineItemDetail[];
}

export class LineItemDetail {
    itemName: string = "";
    quantity: number = 0;
    unitMeasure: string = "";
    unitCost: number = 0;
    itemTotalAmount: number = 0;
    itemCommodityCode: string = "";
    itemProductCode: string = "";
    correctionMessage: string = "";
}

export class PassengerItinerary {
    ticketNumber: string = "";
    passangerName: string = "";
    legInformation: LegInformation[];
}

export class LegInformation {
    legNumber: number = 0;
    travelFrom: string = "";
    travelTo: string = "";
    dateOfTravel: Date = new Date();
    isFinalDestination: boolean = false;
    static readonly Dates: string[] = Util.DatePropertiesKeys(new LegInformation());
    static fixDates(l: LegInformation) {
        return Util.FixDates(l, LegInformation.Dates);
    }
}

export class Transaction{
    id: number = 0;
    accountNumber: string = "";
    transactionReferenceNumber: string = "";
    sequenceNumber: number = 0;
    transactionDate: Date = new Date();
    postingDate: Date = new Date();
    billingAmount: number = 0;
    supplierName: string = "";
    supplierCity: string = "";
    supplierStateProvinceCode: string = "";
    merchantCategoryCode: number = 0;
    billingAccountNumber: string = "";
    customerCodeCri: string = "";
    purchaseIdentification: string = "";
    lineItemSummary?: LineItemSummary;
    passengerItinerary?: PassengerItinerary;
    static readonly Dates: string[] = Util.DatePropertiesKeys(new Transaction());
    static fixDates(t: Transaction) {
        if (t.passengerItinerary)
            t.passengerItinerary.legInformation.forEach(l => LegInformation.fixDates(l));
        return Util.FixDates(this, Transaction.Dates);
    }
}
