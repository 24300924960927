import { Util } from "../util/util";

// $$Implements SW.BE.DTO.CMNT
export class Comment {
  id: number = 0;
  text: string = "";
  viewTypeCd?: string = "";
  deleted: boolean = false;
  editable?: boolean = false;
  entityId?: number = 0;
  creator: string = "";
  createdOn: Date = new Date();
  modifiedBy?: string = "";
  modifiedOn?: Date = new Date();

  static readonly Dates: string[] = Util.DatePropertiesKeys(new Comment());
  static fixDates(c:Comment) {
    return Util.FixDates(c,Comment.Dates);
  }
}
