<div *ngIf="expense">
  <div class="d-flex border border-dark">
    <div class="row flex-fill">
      <div class="col-6">{{currencyString(expense.amount)}}</div>
      <div class="col-6">{{dateString(expense.postdate)}}</div>
      <div class="col-6">{{expense.vendor}}</div>
      <div class="col-6">Line {{expense.lineNumber}}</div>
    </div>
    <icon-text [faicon]="'message'" [text]="(expense.comments.length||'+').toString()" [iconClass]="'fa-regular'" [textStyle]="'transform:translateY(-1.75rem);'" class="float-end m-1" (click)="toggleComments()"></icon-text>
  </div>

  <div *ngIf="!!expense&&showComments" class="p-1 border border-dark"
    style="max-height: 25vh; overflow-y: auto;"
  >
    Comments:
    <button class="btn btn-sm btn-success rounded-pill" (click)="onAddComment.emit(expense.id)">
      <i class="fa fa-solid fa-plus"></i>
    </button>
    <lib-comments 
      #comments
      *ngIf="!!expense"
      [apiURL]="'/api/expenses'"
      [items]="expense.comments"
      [modifiable]="expense.commentable"
      [showHistory]="false"
      (onUpdate)="onCommentsUpdate()"
    ></lib-comments>
  </div>
  
  <div class="row m-1" *ngFor="let dist of expense.distributions">
    <div class=" d-flex col-10">
      <div>
        <button class="btn btn-sm btn-danger rounded-pill fit-cnt me-1" (click)="delDist(dist)"><i class="fa fa-solid fa-trash"></i></button>
        <button class="btn btn-sm btn-warning  rounded-pill fit-cnt" (click)="editDist(dist)"><i class="fa fa-solid fa-pencil"></i></button>
      </div>
      <div>{{fullAcct(dist)}}
        <div *ngIf="dist.description" style="font-size: x-small">{{dist.description}}</div>
      </div>
    </div>
    <div *ngIf="expense.distributions.length>1" class="col-2">
      <span *ngIf="dist.amount!==undefined" (click)="editAmount(dist)">{{currencyString(dist.amount)}}</span>
      <input *ngIf="dist.amount===undefined" 
        type="number" 
        class="w-100 amount-input"
        [(ngModel)]="draftAmount" 
        (blur)="dist.amount = this.draftAmount" 
        (keydown)="onAmountKey($event)" 
        step="0.01"
      >

    </div>
  </div>
  
  <div *ngIf="expense.distributions.length>1" class="row m-1">
    <div class="d-flex justify-content-end">
      <hr class="col-4 m-0">
    </div>
    <label class="col-10 text-end">Balance:</label>
    <div class="col-2">{{currencyString(balance)}}</div>
  </div>
  
  <select class="form-select m-1" aria-label="Default select example" (change)="onFavSelected($event)">
    <option selected style="display: none;">Add MFK from Favorites</option>
    <option *ngFor="let a of favAccounts" value="{{a.account}}" [disabled]="isAcctUsed(a.account)">{{a.description}}</option>
  </select>
  <div class="d-flex m-1">
    <lib-account [(account)]="draftAcct"></lib-account>
    <button type="button" class="btn btn-success align-self-end" [disabled]="isAcctUsed(draftAcct)" [title]="isAcctUsed(draftAcct)?'Account is already used':''" (click)="addDist(draftAcct)">Apply</button>
  </div>

  <div class="d-flex m-1">
    <div>
      <input type="checkbox" [(ngModel)]="applyOptnlJust">
      <label>Add business justification for new distribution (optional)</label>
    </div>
    <div class="d-flex justify-content-end flex-fill" style="scale: .8;">
      <label>SLID/SLAC:</label>
      <input type="text" [(ngModel)]="draftSlid" class="form-control-sm" style="width: 4rem;">/
      <input type="text" [(ngModel)]="draftSlac" class="form-control-sm" style="width: 4rem;">
    </div>
  </div>

  
  <input type="text" placeholder="Business justification / line description" [(ngModel)]="draftJustOptnl" class="w-100">
  
  <div class="my-1">
    <select>
      <option style="display: none;">Other options</option>
      <option>Dispute</option>
      <option>Payrol deduction</option>
    </select>
  </div>

  <div *ngIf="expense.distributions.length==1 || !balance">
    <div>
      <label>Business Justification:</label>
      <button (click)="applyJustBulk(false)">Apply to Remaining Distributions</button>
      <button (click)="applyJustBulk(true)">Apply to All Distributions</button>
    </div>
    <textarea [(ngModel)]="draftJustBulk" class="w-100"></textarea>
  </div>
  
  Attachments:
  <div *ngFor="let a of expense.attachments">
    <a class="flex-grow-1 text-truncate" target="_blank"
      href="/api/expenses/att/{{a.id}}">{{a.filename}}</a>
  </div>
</div>
