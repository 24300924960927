import { Util } from "../util/util";
import { Attachment } from "./attachment";
import { Change } from "./change";
import { Comment } from "./comment";
import { Expense } from "./expense";
import { Person } from "./person";
import { StatusChange } from "./status-change";
import { TripPersonalDate } from "./trip-personal-date";
import { TripContact } from "./trip-contact";
import { TripDest } from "./trip-dest";

// $$Implements SW.BE.DTO.TRIP
export class Trip {
  id: number = 0;
  person: Person = new Person();
  isGroup?: boolean = false;
  purpose?: string = "";
  tripPurposeOther?: string = "";
  description?: string = "";
  emergencyDesc?: string = "";
  dest?: string = "";
  depart: Date = new Date();
  return: Date = new Date();
  pmntMethod?: string = "";
  justification?: string = "";
  justificationCmnt?: string = "";
  intrntnlCmnt?: string = "";
  reminderOptOut?: boolean = false;
  accountingVal?: string = "";
  aeAirfair?: number = 0;
  aeHotel?: number = 0;
  aeMeal?: number = 0;
  aeMileage?: number = 0;
  aeRegistration?: number = 0;
  aeOther?: number = 0;
  trvlAs?: string = "";
  travelerType?: string = "";
  personType?: string = "";
  apprvlRqrd?: boolean = false;
  wfPackageId?: number = 0;
  cancelled?: boolean = false;
  
  creator: string = "";
  createdOn: Date = new Date();
  modifiedBy: string = "";
  modifiedOn: Date = new Date();

  editable?: boolean = true;
  intrntnl?: boolean = false;
  emergency?: boolean = false;

  destinations: TripDest[] = [];
  contacts: TripContact[] = [];
  persDates: TripPersonalDate[] = [];
  comments?: Comment[] = [];
  attachments?: Attachment[] = [];
  statusChanges?: StatusChange[] = [];
  changes?: Change[] = [];
  expenses?: Expense[] = [];

  static readonly Dates: string[] = Util.DatePropertiesKeys(new Trip());
  static fixDates(t: Trip) {
    if (t.destinations)
      t.destinations.map(d => TripDest.fixDates(d));
    if (t.persDates)
      t.persDates.map(d => TripPersonalDate.fixDates(d));
    if (t.comments)
      t.comments.map(c => Comment.fixDates(c));
    if (t.statusChanges)
      t.statusChanges.map(c => StatusChange.fixDates(c));
    if (t.changes)
      t.changes.map(c => Change.fixDates(c));
    if (t.attachments)
      t.attachments.map(x => Attachment.fixDates(x));
    return Util.FixDates(t, Trip.Dates);
  }

}