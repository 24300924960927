import { Component, OnInit, Input, Injectable, ViewChild, ElementRef, Output, EventEmitter, TemplateRef, ContentChild } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpEventType } from '@angular/common/http';


import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ModalService } from '../../modal/modal.service';
import { Util } from 'src/app/core';
import { Attachment } from 'src/app/core/models/attachment';
import { Comment } from 'src/app/core/models/comment';

@Component({
  selector: 'lib-attachments',
  templateUrl: "./attachments.component.html",
  styleUrls: ["./attachments.component.scss"]
})

export class AttachmentsComponent {
  @Input() apiURL: string;
  @Input() modifiable: boolean;
  @Input() selectable: boolean;
  @Input() multiselect: boolean;
  @Input() itemName: string = 'attachment';
  @Input() public set items(items:Attachment[]) {
    this.useAPItoGetItems = false;
    // $$Implements SW.FE.ATT.LIST.DELETED
    this.attachments = items.filter(a => !a.deleted);
    this.deleted = items.filter(a => a.deleted);
  }

  @Input() extrasTemplate: TemplateRef<any>;

  @Output() onUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onMenu: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  dateTimeString = Util.dateString;

  useAPItoGetItems = true;
  attachments: Attachment[];
  deleted: Attachment[];
  constructor(
    private http: HttpClient,
    private modalService:ModalService
  ) { }

  updateItems() {
    if (this.useAPItoGetItems) 
      this.updateFromApi();
    else
      this.onUpdate.emit();
  }

  updateFromApi() {
    this.http.get<Attachment[]>(this.apiURL)//.replace("${id}",this.itemId))
    .subscribe((atts:Attachment[])=>{
      // $$Implements SW.FE.ATT.LIST.DELETED
      this.attachments = atts.filter(a => !a.deleted);
      this.deleted = atts.filter(a => a.deleted);
    })
  }

  commentRows(a:Attachment) {
    let cmnt = a.comment||"";
    return 1+(cmnt.match(/\n/gs)?.length??0);
  }

  onKeyPress(event:KeyboardEvent) {
    if (event.key == "Enter" && !event.shiftKey)
      (<HTMLElement>event.target).blur();
  }
  originalComment:string ="";

  startEditComment(comment: string) {
    this.originalComment = comment;
  }

  // $$Implements SW.FE.ATT.LIST.CMNT
  updateComment(id:number, event:Event) {
    let target:{value:string} = <any>event.target;
    let originalComment = this.originalComment;
    let comment = target.value;
    if (originalComment == comment)
      return;
    this.http.put<any>(`${this.apiURL}/${id}`,{comment})
    .subscribe(
      res => {
          this.updateItems();
    },
    error => {
      target.value = originalComment;
      this.error("Failed to updated description",error)
    }
    );
  }

  onDeleteBtn(id: number) {
    if (this.onDelete.observers.length) {
      // $$Implements SW.FE.ATT.LIST.DEL_EVT
      this.onDelete.emit({id});
      return;
    }
    // $$Implements SW.FE.ATT.LIST.DEL
    this.del(id);
  }

  // $$Implements SW.FE.ATT.LIST.DEL
  del(id: number, msg?:string) {
    msg = msg || `Would you like to delete this ${this.itemName}?`;
    this.modalService.ask(`Delete ${this.itemName}`, msg)
    .pipe(
      switchMap(res => {
        return res ?
          this.http.delete(`${this.apiURL}/${id}`).pipe(switchMap(_ => of(true)))
          : of(false)
      })
    )
    .subscribe(
      res => {
        if (res)
            this.updateItems();
      },
      error => {
        this.error("Failed to delete attachment", error)
      }
    );
  }

  cmntHist:Comment[] = [];
  @ViewChild("cmntHistTmpl") cmntHistTmpl: TemplateRef<any>;

  // $$Implements  SW.FE.ATT.CMNT_HIST
  showCmntHist(id: number) {
    this.http.get<Comment[]>(`${this.apiURL}/cmnts/${id}`).pipe(
      map(r => {
        return r.map(c => Comment.fixDates(c));
      })
    )
    .subscribe(
      res => {
        if (!res.length) {
          this.modalService.notify(
            "Comments history",
            "This attachment does not have any comments yet"
            ).subscribe();
          return;
        }
        this.cmntHist = res;
        this.modalService.show({
          title: "Comments history",
          template: this.cmntHistTmpl
        }).subscribe();
      },
      error => {
        this.error("Failed to fetch comments history", error)
      }
    );
  }

  error(msg,err) {
    err = err||{};
    msg += ":<br>" + (Util.errorMessage(err) || "");
    this.modalService.notify("Error",msg).subscribe();
  }

  selected: number;
  rowStyle(i: number):string {
    let style = this.selectable ? 'cursor-default' : '';
    if (i === this.selected)
      style += " selected";
    return style;
  }

  onRowClick(i:number) {
    if (!this.selectable)
      return;
    this.selected = i;
    this.onSelect.emit(i);
  }

  unselect() {
    this.selected = undefined;
  }
}
