import { Person } from "./person";

export interface OrgUnit {
  accessScope:string;
  description:string;
  uss?:boolean;
  ussEmails?:string[];
  units?:{[Code:string]:OrgUnit};
  favorite?: boolean;
  expanded?: boolean;
  people?: Person[];
}

export function filterUnits(unit: OrgUnit, pred: (u: OrgUnit) => boolean, deep?: boolean): OrgUnit[] {
  let res: OrgUnit[] = [];
  if (pred(unit))
    res.push(unit);
  if ((deep || !res.length) && unit.units)
      res = res.concat(Object.values(unit.units).reduce((p, c) => p.concat(filterUnits(c, pred, deep)), []));
  return res;
}

export function reduceUnits<T>(unit: OrgUnit, callbackfn: (previousValue: T, currentValue: OrgUnit) => T, initialValue: T): T {
  let res = callbackfn(initialValue, unit);
  if (unit.units)
    res = Object.values(unit.units).reduce((p, c) => reduceUnits(c, callbackfn, p), res);
  return res;
}

export function iterateUnits(unit: OrgUnit, cb: (u: OrgUnit) => void) {
  cb(unit);
  if (unit.units)
    Object.values(unit.units).forEach((u) => iterateUnits(u,cb));
}

