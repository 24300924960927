import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { Route, Routes } from "@angular/router";
import { UserService } from "src/app/core";

@Component({
  selector: 'lib-nav-tiles',
  templateUrl: "./nav-tiles.component.html",
  styleUrls: ["./nav-tiles.component.scss"]
})
export class NavTilesComponent implements AfterViewInit {
  @Input() routes: Routes;
  @Input() linkPaths: string[];

  linkPathSet: Set<string>;
  tiles: Route[] = [];
  constructor(
    private readonly userService: UserService,
  ) { }

  ngAfterViewInit(): void {
    this.linkPathSet = new Set<string>(this.linkPaths)
    this.userService.user$.subscribe(u => setTimeout(() => {
      this.tiles = this.routes.filter(r =>
        this.linkPathSet.has(r.path) && (r.data?.roles?.some(r => u.roles.has(r)) ?? true));
    }))
  }
}
