export enum AppEventType {
  ExpenseStateChanged = 'XP_STATE',
  ExpenseReportStateChanged = 'XPR_STATE',
  UrlUpdated = 'AE_URL'
}

export class AppEvent<T> {
  constructor(
    public type: AppEventType,
    public payload: T,
  ) { }
}
