import { Component, Input } from "@angular/core";

@Component({
  selector: 'icon-text',
  templateUrl: './iconText.component.html',
  styleUrls: ['./iconText.component.scss']
})
export class IconTextComponent {
  @Input() faicon: string;
  @Input() color: string;
  @Input() text: string;
  @Input() textClass: string;
  @Input() textStyle: string;
  @Input() iconClass: string;

  get iClass():string {
    return `fa fa-stack-2x fa-${this.faicon} ${this.iconClass}`;
  }
  get iconStyle():string {
    return `color: ${this.color};`;
  }
}