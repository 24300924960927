<div class="expense_distribution">
<div *ngIf="createButton && expense.editable && (expense.reconciled < expense.amount)"> 
  <div class="d-flex justify-content-center">
    <!-- $$Implements SW.FE.XP.PAGE.DTL.ADD_DIST -->
    <button  class="btn btn-sm btn-success m-1 col-2"
      (click)="createDistribution()" style="max-width: 10rem;">Create</button>
  </div>
</div>
  <div class="col-12" *ngIf="this.ded && this.ded.id == 0">
    <ng-container *ngTemplateOutlet="distEditor"></ng-container>
    <hr class="col-11">
  </div>
  <!-- $ SW.FE.XP.PAGE.DTL.DISTS -->
  <ul class="pe-2">
    <li *ngFor="let d of expense.distributions" class="row">
      <hr class="col-11">
      <div class="col-11 row" [attr.role]="expense.editable?'button':''" (click)="editDist(d.id)">
        <span class="col-12 text-wrap">{{d.description}}</span>
        <span class="col-8">{{d.account}}</span>
        <span class="col-2"><span *ngIf="d.slid||d.slac"><b>SLID/SLAC</b>&nbsp;{{d.slid}}/{{d.slac}}</span></span>
        <span class="col-2 text-end">{{ currencyString(d.amount) }}</span>
        <br>
        <span style="font-size: smaller" class="col-12 text-end"><i>Last updated by {{d.modifiedBy}}
            &#64; {{dateTimeString(d.modifiedOn)}}</i></span>
      </div>
      <div *ngIf="expense.editable" class="col-1">
        <button class="btn btn-sm btn-danger fit-cnt" (click)="delDist(d.id)"><i class="fa fa-solid fa-trash"></i></button>
      </div>
      <div class="col-12" *ngIf="this.ded && this.ded.id == d.id">
        <ng-container *ngTemplateOutlet="distEditor"></ng-container>
      </div>
    </li>
  </ul>
</div>

<!-- $$Implements SW.FE.PAGE.DTL.DIST_EDIT -->
<ng-template #distEditor>
  <div class="d-flex flex-column">
    <div class="col-12 d-flex align-items-end p-2">
      <div class="col-2 justify-content-end">
        <span class="card-field-label col-6">Amount</span>
        <input class="card-field-value col-6" type="number" [(ngModel)]="ded.amount" [max]="expense.amount" step="0.01">
      </div>
      <div class="col-10 px-1">
        <lib-account [(account)]="ded.account" class="col-12"></lib-account>
      </div>
    </div>
    <div class="d-flex px-1 justify-content-start">
      <div class="card-field-label col-2 text-wrap">Line Description/Business Justification:</div>
      <textarea [(ngModel)]="ded.desc" rows="2" class="col-7" placeholder="Required"></textarea>
      <div class="col-3 p-1 row align-items-middle">
        <span class="card-field-label col-6 text-end">SLID/SLAC:</span>
        <span class="col-2 px-0"><input type="text" maxlength="1" [(ngModel)]="ded.slid" style="width: 100%;" /></span>/
        <span class="col-3 px-0"><input type="text" maxlength="16" class="col-6" [(ngModel)]="ded.slac"
            style="width: 100%;" /></span>
      </div>
    </div>
    <div class="row p-1 justify-content-center">
      <button type="button" class="btn btn-default col-1" (click)="closeDistEditor(false)">Cancel</button>
      <button type="button" class="btn btn-danger col-1" [title]="okButtonHelp()" [disabled]="okButtonHelp()"
        (click)="closeDistEditor(true)">
        Save
      </button>
    </div>

  </div>

</ng-template>